import { getDeathCountsByAgeAndReason } from "./Reasons";
import jsPDF from "jspdf";
import "jspdf-autotable";

export const generatallPDF = async ({
  ageGroup,
  value,
  year,
  reasonAllDeathCount,
  user,
}) => {
  const pdf = new jsPDF();

  // Header and Footer to Each Page
  const headerBarColor = [45, 176, 188]; // RGB color for the background bar
  pdf.setFontSize(10); // Header and footer font size
  const headerText =
    "Suicide Statistics and Insights with a Comprehensive Analysis of Suicidal Reasons\n                                      powered by www.safeguardAI.com";
  const footerText =
    "        Promoting Mental Health and Suicide Prevention | All Rights Reserved www.safeguardAI.com";

  const currentDate = new Date();
  const yearjs = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const amPm = hours >= 12 ? "PM" : "AM";
  const formattedHours = String(hours % 12 || 12).padStart(2, "0");

  const formattedDate = `${yearjs}-${month}-${day}  ${formattedHours}:${minutes}`;

  const topSentence = `Reason : For all Reasons (recent 5 years)\nPredicted Year : ${year}\nAge Group : ${ageGroup.replace(
    "-",
    " - "
  )}\nDate : ${formattedDate} ${amPm}\nGenerated by : ${user.firstName} ${
    user.lastName
  }`;
  const lines = topSentence.split("\n");
  const lineHeight = pdf.internal.getFontSize() * 0.7;

  const textSize =
    (pdf.getStringUnitWidth(topSentence) * pdf.internal.getFontSize()) /
    pdf.internal.scaleFactor;

  pdf.setFontSize(12);

  const centerX = pdf.internal.pageSize.width / 15;
  let centerY = 30;

  lines.forEach((line) => {
    const textSize =
      (pdf.getStringUnitWidth(line) * pdf.internal.getFontSize()) /
      pdf.internal.scaleFactor;
    const y = centerY;

    pdf.text(line, centerX, y);
    centerY += lineHeight;
  });

  // Header Background Bar
  pdf.setFillColor(...headerBarColor);
  pdf.rect(3, 3, pdf.internal.pageSize.width - 2 * 3, 20, "F");

  // Header Text
  pdf.setTextColor(255, 255, 255);
  const headerWidth =
    (pdf.getStringUnitWidth(headerText) * pdf.internal.getFontSize()) /
    pdf.internal.scaleFactor;
  const pageWidth = pdf.internal.pageSize.width;
  const headerX = (pageWidth - headerWidth) / 2;
  pdf.text(headerText, 28, 12);

  // Footer Text
  pdf.setFontSize(10);
  pdf.text(footerText, 10, pdf.internal.pageSize.height - 10);

  //get the predcited value for each reason

  const getDeathCount = (value) => {
    let targetValue = null;
    for (const item of reasonAllDeathCount) {
      if (value in item) {
        targetValue = item[value];
        break;
      }
    }
    return targetValue;
  };

  // Define table headers and rows
  const tableColums = [
    { title: "Age Group", field: "AgeGroup" },
    { title: "Reason", field: "Reason" },
    { title: "Year", field: "Year" },
    { title: "Death Count", field: "DeathCount" },
  ];

  const dataEconomic = getDeathCountsByAgeAndReason(
    ageGroup,
    "EconomicProblems"
  );

  const dataHarrasment = getDeathCountsByAgeAndReason(ageGroup, "Harrasment");

  const dataLoveAffairs = getDeathCountsByAgeAndReason(ageGroup, "LoveAffairs");

  const dataDrugs = getDeathCountsByAgeAndReason(ageGroup, "Drugs");

  const dataMentaldisorders = getDeathCountsByAgeAndReason(
    ageGroup,
    "Mentaldisorders"
  );

  const dataEmploymentProblems = getDeathCountsByAgeAndReason(
    ageGroup,
    "EmploymentProblems"
  );

  const tableDataEconomic = dataEconomic.map((item) => ({
    AgeGroup: ageGroup,
    Reason: "Economic Problems",
    ...item,
  }));

  const tableDataHarrasment = dataHarrasment.map((item) => ({
    AgeGroup: ageGroup,
    Reason: "Harrasment",
    ...item,
  }));

  const tableDataLoveAffairs = dataLoveAffairs.map((item) => ({
    AgeGroup: ageGroup,
    Reason: "Love Affairs",
    ...item,
  }));

  const tabledataDrugs = dataDrugs.map((item) => ({
    AgeGroup: ageGroup,
    Reason: "Drugs",
    ...item,
  }));

  const tabledataMentaldisorders = dataMentaldisorders.map((item) => ({
    AgeGroup: ageGroup,
    Reason: "Mental Disorders",
    ...item,
  }));

  const tabledataEmploymentProblems = dataEmploymentProblems.map((item) => ({
    AgeGroup: ageGroup,
    Reason: "Employment Problems",
    ...item,
  }));

  pdf.setLineWidth(1);
  pdf.setDrawColor("#8b8c8b");

  const borderWidth = 2;
  const contentWidth = pdf.internal.pageSize.width - 2 * borderWidth;
  const contentHeight = pdf.internal.pageSize.height - 2 * borderWidth;
  pdf.rect(borderWidth, borderWidth, contentWidth, contentHeight, "D");

  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(12);
  pdf.text("Economic Problems", 14, 68);

  //Strangling Method
  pdf.autoTable({
    theme: "grid",
    columns: tableColums.map((col) => ({ ...col, dataKey: col.field })),
    headStyles: { fillColor: "#2cb1bc" },
    body: [
      ...tableDataEconomic,
      {
        AgeGroup: ageGroup,
        Reason: "Economic Problems",
        Year: year,
        DeathCount: getDeathCount(1),
      },
    ],
    startY: 71,
    didDrawCell: (data) => {
      const lastRowIndex = tableDataEconomic.length;

      if (data.row.index === lastRowIndex) {
        // Highlight the last row's cells with a border
        pdf.setDrawColor(255, 0, 0);
        pdf.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
      }
    },
  });

  //Harrasment
  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(12);
  pdf.text("Harrasment", 14, 138);
  pdf.autoTable({
    theme: "grid",
    columns: tableColums.map((col) => ({ ...col, dataKey: col.field })),
    headStyles: { fillColor: "#2cb1bc" },
    body: [
      ...tableDataHarrasment,
      {
        AgeGroup: ageGroup,
        Reason: "Harrasment",
        Year: year,
        DeathCount: getDeathCount(4),
      },
    ],

    startY: 141,
    didDrawCell: (data) => {
      const lastRowIndex = tableDataHarrasment.length;

      if (data.row.index === lastRowIndex) {
        // Highlight the last row's cells with a border
        pdf.setDrawColor(255, 0, 0);
        pdf.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
      }
    },
  });

  //Love Affairs
  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(12);
  pdf.text("Love Affairs", 14, 209);
  pdf.autoTable({
    theme: "grid",
    columns: tableColums.map((col) => ({ ...col, dataKey: col.field })),
    headStyles: { fillColor: "#2cb1bc" },
    body: [
      ...tableDataLoveAffairs,
      {
        AgeGroup: ageGroup,
        Reason: "Love Affairs",
        Year: year,
        DeathCount: getDeathCount(5),
      },
    ],

    startY: 212,
    didDrawCell: (data) => {
      const lastRowIndex = tableDataLoveAffairs.length;

      if (data.row.index === lastRowIndex) {
        // Highlight the last row's cells with a border
        pdf.setDrawColor(255, 0, 0);
        pdf.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
      }
    },
  });

  pdf.addPage();

  const headerBarColorr = [45, 176, 188]; // RGB color for the background bar
  pdf.setFontSize(10); // Header and footer font size

  // Header Background Bar to the Second Page
  pdf.setFillColor(...headerBarColorr);
  pdf.rect(3, 3, pdf.internal.pageSize.width - 2 * 3, 20, "F");

  const topSentencee = ``;
  const linese = topSentencee.split("\n");
  const lineHeighte = pdf.internal.getFontSize() * 13;

  const textSizee =
    (pdf.getStringUnitWidth(topSentencee) * pdf.internal.getFontSize()) /
    pdf.internal.scaleFactor;

  pdf.setFontSize(12);

  const centerXe = pdf.internal.pageSize.width / 15;
  let centerYe = 0;

  linese.forEach((line) => {
    const textSize =
      (pdf.getStringUnitWidth(line) * pdf.internal.getFontSize()) /
      pdf.internal.scaleFactor;
    const y = centerY;

    pdf.text(line, centerXe, y);
    centerYe += lineHeighte;
  });

  // Header Text to the Second Page
  pdf.setTextColor(255, 255, 255);
  pdf.text(headerText, 28, 12);

  // Footer Text to the Second Page
  pdf.setFontSize(12);
  pdf.setTextColor(0, 0, 0);
  pdf.text(footerText, 10, pdf.internal.pageSize.height - 10);

  //Drugs
  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(12);
  pdf.text("Drugs", 14, 42);
  pdf.autoTable({
    theme: "grid",
    columns: tableColums.map((col) => ({ ...col, dataKey: col.field })),
    headStyles: { fillColor: "#2cb1bc" },
    body: [
      ...tabledataDrugs,
      {
        AgeGroup: ageGroup,
        Reason: "Drugs",
        Year: year,
        DeathCount: getDeathCount(7),
      },
    ],

    startY: 45,
    didDrawCell: (data) => {
      const lastRowIndex = tabledataDrugs.length;

      if (data.row.index === lastRowIndex) {
        // Highlight the last row's cells with a border
        pdf.setDrawColor(255, 0, 0);
        pdf.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
      }
    },
  });

  pdf.setLineWidth(1);
  pdf.setDrawColor("#8b8c8b");
  pdf.rect(borderWidth, borderWidth, contentWidth, contentHeight, "D");

  //Mentaldisorders
  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(12);
  pdf.text("Mental Disorders", 14, 115);
  pdf.autoTable({
    theme: "grid",
    columns: tableColums.map((col) => ({ ...col, dataKey: col.field })),
    headStyles: { fillColor: "#2cb1bc" },
    body: [
      ...tabledataMentaldisorders,
      {
        AgeGroup: ageGroup,
        Reason: "Mental Disorders",
        Year: year,
        DeathCount: getDeathCount(11),
      },
    ],

    startY: 118,
    didDrawCell: (data) => {
      const lastRowIndex = tabledataMentaldisorders.length;

      if (data.row.index === lastRowIndex) {
        // Highlight the last row's cells with a border
        pdf.setDrawColor(255, 0, 0);
        pdf.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
      }
    },
  });

  //Employment Problems
  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(12);
  pdf.text("Employment Problems", 14, 188);
  pdf.autoTable({
    theme: "grid",
    columns: tableColums.map((col) => ({ ...col, dataKey: col.field })),
    headStyles: { fillColor: "#2cb1bc" },
    body: [
      ...tabledataEmploymentProblems,
      {
        AgeGroup: ageGroup,
        Reason: "Employment Problems",
        Year: year,
        DeathCount: getDeathCount(2),
      },
    ],

    startY: 191,
    didDrawCell: (data) => {
      const lastRowIndex = tabledataEmploymentProblems.length;

      if (data.row.index === lastRowIndex) {
        // Highlight the last row's cells with a border
        pdf.setDrawColor(255, 0, 0);
        pdf.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
      }
    },
  });

  pdf.save("all_reasons.pdf");
};
