export const reasonData = [
  {
    "Age Group": "0-8",
    Reason: "EconomicProblems",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Reason: "EmploymentProblems",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Reason: "Harrasment",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Reason: "LoveAffairs",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Reason: "Drugs",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Reason: "Mentaldisorders",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Reason: "OtherReasons",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Reason: "EconomicProblems",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Reason: "EmploymentProblems",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 19,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Reason: "Harrasment",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Reason: "LoveAffairs",
    "Death Count": 17,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Reason: "Drugs",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Reason: "FailureAtTheExamination",
    "Death Count": 4,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Reason: "Mentaldisorders",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Reason: "OtherReasons",
    "Death Count": 20,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Reason: "EconomicProblems",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Reason: "EmploymentProblems",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 30,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Reason: "Harrasment",
    "Death Count": 10,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Reason: "LoveAffairs",
    "Death Count": 90,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Reason: "Drugs",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Reason: "FailureAtTheExamination",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Reason: "Mentaldisorders",
    "Death Count": 9,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Reason: "OtherReasons",
    "Death Count": 60,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Reason: "EconomicProblems",
    "Death Count": 11,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Reason: "EmploymentProblems",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 12,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Reason: "Harrasment",
    "Death Count": 45,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Reason: "LoveAffairs",
    "Death Count": 75,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Reason: "SexualHarrassment",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Reason: "Drugs",
    "Death Count": 9,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Reason: "FailureAtTheExamination",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Reason: "Mentaldisorders",
    "Death Count": 20,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Reason: "OtherReasons",
    "Death Count": 89,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Reason: "EconomicProblems",
    "Death Count": 22,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Reason: "EmploymentProblems",
    "Death Count": 8,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Reason: "Harrasment",
    "Death Count": 86,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Reason: "LoveAffairs",
    "Death Count": 54,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Reason: "Drugs",
    "Death Count": 19,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 6,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Reason: "Mentaldisorders",
    "Death Count": 15,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 9,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Reason: "OtherReasons",
    "Death Count": 103,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Reason: "EconomicProblems",
    "Death Count": 25,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Reason: "EmploymentProblems",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Reason: "Harrasment",
    "Death Count": 102,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Reason: "LoveAffairs",
    "Death Count": 23,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Reason: "Drugs",
    "Death Count": 12,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Reason: "Mentaldisorders",
    "Death Count": 10,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Reason: "OtherReasons",
    "Death Count": 95,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Reason: "EconomicProblems",
    "Death Count": 22,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Reason: "EmploymentProblems",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Reason: "Harrasment",
    "Death Count": 82,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Reason: "LoveAffairs",
    "Death Count": 6,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Reason: "Drugs",
    "Death Count": 10,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Reason: "LossOfProperty",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Reason: "Mentaldisorders",
    "Death Count": 15,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 8,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Reason: "OtherReasons",
    "Death Count": 90,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Reason: "EconomicProblems",
    "Death Count": 35,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Reason: "EmploymentProblems",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Reason: "Harrasment",
    "Death Count": 90,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Reason: "LoveAffairs",
    "Death Count": 10,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Reason: "Drugs",
    "Death Count": 13,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Reason: "LossOfProperty",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Reason: "Mentaldisorders",
    "Death Count": 19,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 15,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Reason: "OtherReasons",
    "Death Count": 83,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Reason: "EconomicProblems",
    "Death Count": 32,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Reason: "EmploymentProblems",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Reason: "Harrasment",
    "Death Count": 66,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Reason: "LoveAffairs",
    "Death Count": 8,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Reason: "Drugs",
    "Death Count": 12,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Reason: "LossOfProperty",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Reason: "Mentaldisorders",
    "Death Count": 22,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 19,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Reason: "OtherReasons",
    "Death Count": 106,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Reason: "EconomicProblems",
    "Death Count": 20,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Reason: "EmploymentProblems",
    "Death Count": 6,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Reason: "Harrasment",
    "Death Count": 62,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Reason: "LoveAffairs",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Reason: "Drugs",
    "Death Count": 13,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Reason: "Mentaldisorders",
    "Death Count": 42,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 39,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Reason: "OtherReasons",
    "Death Count": 115,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Reason: "EconomicProblems",
    "Death Count": 20,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Reason: "EmploymentProblems",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Reason: "Harrasment",
    "Death Count": 59,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Reason: "LoveAffairs",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Reason: "Drugs",
    "Death Count": 25,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Reason: "LossOfProperty",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Reason: "Mentaldisorders",
    "Death Count": 65,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 52,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Reason: "OtherReasons",
    "Death Count": 103,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Reason: "EconomicProblems",
    "Death Count": 10,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Reason: "EmploymentProblems",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Reason: "Harrasment",
    "Death Count": 29,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Reason: "LoveAffairs",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Reason: "Drugs",
    "Death Count": 14,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Reason: "LossOfProperty",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Reason: "Mentaldisorders",
    "Death Count": 74,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 71,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Reason: "OtherReasons",
    "Death Count": 90,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Reason: "EconomicProblems",
    "Death Count": 11,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Reason: "EmploymentProblems",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Reason: "Harrasment",
    "Death Count": 19,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Reason: "LoveAffairs",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Reason: "Drugs",
    "Death Count": 6,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 8,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Reason: "Mentaldisorders",
    "Death Count": 52,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 52,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Reason: "OtherReasons",
    "Death Count": 79,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Reason: "EconomicProblems",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Reason: "EmploymentProblems",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Reason: "Harrasment",
    "Death Count": 6,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Reason: "LoveAffairs",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Reason: "Drugs",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 4,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Reason: "LossOfProperty",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Reason: "Mentaldisorders",
    "Death Count": 115,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 122,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Reason: "OtherReasons",
    "Death Count": 118,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Reason: "EconomicProblems",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Reason: "EmploymentProblems",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Reason: "Harrasment",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Reason: "LoveAffairs",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Reason: "Drugs",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Reason: "Mentaldisorders",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Reason: "OtherReasons",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Reason: "EconomicProblems",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Reason: "EmploymentProblems",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 14,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Reason: "Harrasment",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Reason: "LoveAffairs",
    "Death Count": 20,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Reason: "Drugs",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 4,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Reason: "FailureAtTheExamination",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Reason: "Mentaldisorders",
    "Death Count": 4,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 5,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Reason: "OtherReasons",
    "Death Count": 40,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Reason: "EconomicProblems",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Reason: "EmploymentProblems",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 16,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Reason: "Harrasment",
    "Death Count": 15,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Reason: "LoveAffairs",
    "Death Count": 87,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Reason: "Drugs",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 8,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Reason: "FailureAtTheExamination",
    "Death Count": 5,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Reason: "Mentaldisorders",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Reason: "OtherReasons",
    "Death Count": 61,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Reason: "EconomicProblems",
    "Death Count": 14,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Reason: "EmploymentProblems",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 20,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Reason: "Harrasment",
    "Death Count": 47,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Reason: "LoveAffairs",
    "Death Count": 61,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Reason: "SexualHarrassment",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Reason: "Drugs",
    "Death Count": 9,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Reason: "FailureAtTheExamination",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Reason: "Mentaldisorders",
    "Death Count": 12,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Reason: "OtherReasons",
    "Death Count": 87,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Reason: "EconomicProblems",
    "Death Count": 19,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Reason: "EmploymentProblems",
    "Death Count": 6,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 4,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Reason: "Harrasment",
    "Death Count": 72,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Reason: "LoveAffairs",
    "Death Count": 48,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Reason: "Drugs",
    "Death Count": 15,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 4,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Reason: "Mentaldisorders",
    "Death Count": 13,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 7,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Reason: "OtherReasons",
    "Death Count": 98,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Reason: "EconomicProblems",
    "Death Count": 21,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Reason: "EmploymentProblems",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 4,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Reason: "Harrasment",
    "Death Count": 97,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Reason: "LoveAffairs",
    "Death Count": 24,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Reason: "Drugs",
    "Death Count": 10,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Reason: "LossOfProperty",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Reason: "FailureAtTheExamination",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Reason: "Mentaldisorders",
    "Death Count": 7,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 6,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Reason: "OtherReasons",
    "Death Count": 97,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Reason: "EconomicProblems",
    "Death Count": 20,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Reason: "EmploymentProblems",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Reason: "Harrasment",
    "Death Count": 79,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Reason: "LoveAffairs",
    "Death Count": 8,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Reason: "Drugs",
    "Death Count": 12,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 4,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Reason: "Mentaldisorders",
    "Death Count": 11,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 9,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Reason: "OtherReasons",
    "Death Count": 95,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Reason: "EconomicProblems",
    "Death Count": 33,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Reason: "EmploymentProblems",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Reason: "Harrasment",
    "Death Count": 91,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Reason: "LoveAffairs",
    "Death Count": 8,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Reason: "Drugs",
    "Death Count": 15,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Reason: "LossOfProperty",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Reason: "Mentaldisorders",
    "Death Count": 17,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 14,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Reason: "OtherReasons",
    "Death Count": 75,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Reason: "EconomicProblems",
    "Death Count": 26,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Reason: "EmploymentProblems",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Reason: "Harrasment",
    "Death Count": 61,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Reason: "LoveAffairs",
    "Death Count": 6,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Reason: "Drugs",
    "Death Count": 15,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 6,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Reason: "Mentaldisorders",
    "Death Count": 19,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 17,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Reason: "OtherReasons",
    "Death Count": 100,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Reason: "EconomicProblems",
    "Death Count": 18,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Reason: "EmploymentProblems",
    "Death Count": 5,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Reason: "Harrasment",
    "Death Count": 52,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Reason: "LoveAffairs",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Reason: "Drugs",
    "Death Count": 12,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Reason: "Mentaldisorders",
    "Death Count": 38,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 35,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Reason: "OtherReasons",
    "Death Count": 113,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Reason: "EconomicProblems",
    "Death Count": 18,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Reason: "EmploymentProblems",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Reason: "Harrasment",
    "Death Count": 52,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Reason: "LoveAffairs",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Reason: "Drugs",
    "Death Count": 24,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 5,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Reason: "Mentaldisorders",
    "Death Count": 55,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 50,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Reason: "OtherReasons",
    "Death Count": 100,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Reason: "EconomicProblems",
    "Death Count": 8,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Reason: "EmploymentProblems",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Reason: "Harrasment",
    "Death Count": 34,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Reason: "LoveAffairs",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Reason: "Drugs",
    "Death Count": 12,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 5,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Reason: "Mentaldisorders",
    "Death Count": 68,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 70,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Reason: "OtherReasons",
    "Death Count": 87,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Reason: "EconomicProblems",
    "Death Count": 10,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Reason: "EmploymentProblems",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Reason: "Harrasment",
    "Death Count": 17,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Reason: "LoveAffairs",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Reason: "Drugs",
    "Death Count": 5,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 4,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Reason: "Mentaldisorders",
    "Death Count": 47,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 48,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Reason: "OtherReasons",
    "Death Count": 76,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Reason: "EconomicProblems",
    "Death Count": 6,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Reason: "EmploymentProblems",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Reason: "Harrasment",
    "Death Count": 6,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Reason: "LoveAffairs",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Reason: "Drugs",
    "Death Count": 5,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 6,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Reason: "LossOfProperty",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Reason: "Mentaldisorders",
    "Death Count": 112,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 114,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Reason: "OtherReasons",
    "Death Count": 119,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Reason: "EconomicProblems",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Reason: "EmploymentProblems",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Reason: "Harrasment",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Reason: "LoveAffairs",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Reason: "Drugs",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Reason: "Mentaldisorders",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Reason: "OtherReasons",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Reason: "EconomicProblems",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Reason: "EmploymentProblems",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 20,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Reason: "Harrasment",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Reason: "LoveAffairs",
    "Death Count": 30,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Reason: "Drugs",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 4,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Reason: "FailureAtTheExamination",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Reason: "Mentaldisorders",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Reason: "OtherReasons",
    "Death Count": 17,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Reason: "EconomicProblems",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Reason: "EmploymentProblems",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 28,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Reason: "Harrasment",
    "Death Count": 20,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Reason: "LoveAffairs",
    "Death Count": 93,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Reason: "Drugs",
    "Death Count": 5,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 4,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Reason: "FailureAtTheExamination",
    "Death Count": 4,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Reason: "Mentaldisorders",
    "Death Count": 9,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 6,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Reason: "OtherReasons",
    "Death Count": 50,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Reason: "EconomicProblems",
    "Death Count": 24,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Reason: "EmploymentProblems",
    "Death Count": 5,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 7,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Reason: "Harrasment",
    "Death Count": 56,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Reason: "LoveAffairs",
    "Death Count": 78,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Reason: "Drugs",
    "Death Count": 6,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 4,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Reason: "FailureAtTheExamination",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Reason: "Mentaldisorders",
    "Death Count": 23,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 6,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Reason: "OtherReasons",
    "Death Count": 82,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Reason: "EconomicProblems",
    "Death Count": 9,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Reason: "EmploymentProblems",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 9,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Reason: "Harrasment",
    "Death Count": 67,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Reason: "LoveAffairs",
    "Death Count": 37,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Reason: "Drugs",
    "Death Count": 6,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Reason: "LossOfProperty",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Reason: "Mentaldisorders",
    "Death Count": 9,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 9,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Reason: "OtherReasons",
    "Death Count": 86,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Reason: "EconomicProblems",
    "Death Count": 18,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Reason: "EmploymentProblems",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Reason: "Harrasment",
    "Death Count": 95,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Reason: "LoveAffairs",
    "Death Count": 13,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Reason: "Drugs",
    "Death Count": 10,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Reason: "Mentaldisorders",
    "Death Count": 27,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 8,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Reason: "OtherReasons",
    "Death Count": 69,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Reason: "EconomicProblems",
    "Death Count": 19,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Reason: "EmploymentProblems",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 4,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Reason: "Harrasment",
    "Death Count": 83,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Reason: "LoveAffairs",
    "Death Count": 8,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Reason: "Drugs",
    "Death Count": 7,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Reason: "Mentaldisorders",
    "Death Count": 18,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 13,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Reason: "OtherReasons",
    "Death Count": 70,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Reason: "EconomicProblems",
    "Death Count": 18,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Reason: "EmploymentProblems",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Reason: "Harrasment",
    "Death Count": 76,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Reason: "LoveAffairs",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Reason: "Drugs",
    "Death Count": 14,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Reason: "Mentaldisorders",
    "Death Count": 16,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 17,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Reason: "OtherReasons",
    "Death Count": 79,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Reason: "EconomicProblems",
    "Death Count": 14,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Reason: "EmploymentProblems",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Reason: "Harrasment",
    "Death Count": 64,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Reason: "LoveAffairs",
    "Death Count": 6,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Reason: "SexualHarrassment",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Reason: "Drugs",
    "Death Count": 21,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Reason: "Mentaldisorders",
    "Death Count": 22,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 21,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Reason: "OtherReasons",
    "Death Count": 88,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Reason: "EconomicProblems",
    "Death Count": 13,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Reason: "EmploymentProblems",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 4,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Reason: "Harrasment",
    "Death Count": 54,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Reason: "LoveAffairs",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Reason: "SexualHarrassment",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Reason: "Drugs",
    "Death Count": 22,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Reason: "LossOfProperty",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Reason: "Mentaldisorders",
    "Death Count": 21,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 44,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Reason: "OtherReasons",
    "Death Count": 116,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Reason: "EconomicProblems",
    "Death Count": 16,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Reason: "EmploymentProblems",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Reason: "Harrasment",
    "Death Count": 37,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Reason: "LoveAffairs",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Reason: "SexualHarrassment",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Reason: "Drugs",
    "Death Count": 15,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 5,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Reason: "LossOfProperty",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Reason: "Mentaldisorders",
    "Death Count": 22,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 43,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Reason: "OtherReasons",
    "Death Count": 95,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Reason: "EconomicProblems",
    "Death Count": 9,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Reason: "EmploymentProblems",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Reason: "Harrasment",
    "Death Count": 26,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Reason: "LoveAffairs",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Reason: "Drugs",
    "Death Count": 11,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 6,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Reason: "LossOfProperty",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Reason: "Mentaldisorders",
    "Death Count": 19,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 39,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Reason: "OtherReasons",
    "Death Count": 84,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Reason: "EconomicProblems",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Reason: "EmploymentProblems",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Reason: "Harrasment",
    "Death Count": 9,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Reason: "LoveAffairs",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Reason: "Drugs",
    "Death Count": 6,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 7,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Reason: "LossOfProperty",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Reason: "Mentaldisorders",
    "Death Count": 18,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 55,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Reason: "OtherReasons",
    "Death Count": 91,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Reason: "EconomicProblems",
    "Death Count": 9,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Reason: "EmploymentProblems",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Reason: "Harrasment",
    "Death Count": 15,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Reason: "LoveAffairs",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Reason: "Drugs",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Reason: "LossOfProperty",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Reason: "Mentaldisorders",
    "Death Count": 37,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 119,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Reason: "OtherReasons",
    "Death Count": 142,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Reason: "EconomicProblems",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Reason: "EmploymentProblems",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Reason: "Harrasment",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Reason: "LoveAffairs",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Reason: "Drugs",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Reason: "Mentaldisorders",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Reason: "OtherReasons",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Reason: "EconomicProblems",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Reason: "EmploymentProblems",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 21,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Reason: "Harrasment",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Reason: "LoveAffairs",
    "Death Count": 12,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Reason: "Drugs",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Reason: "FailureAtTheExamination",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Reason: "Mentaldisorders",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Reason: "OtherReasons",
    "Death Count": 24,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Reason: "EconomicProblems",
    "Death Count": 5,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Reason: "EmploymentProblems",
    "Death Count": 4,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 32,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Reason: "Harrasment",
    "Death Count": 22,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Reason: "LoveAffairs",
    "Death Count": 73,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Reason: "SexualHarrassment",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Reason: "Drugs",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 5,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Reason: "FailureAtTheExamination",
    "Death Count": 3,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Reason: "Mentaldisorders",
    "Death Count": 14,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Reason: "OtherReasons",
    "Death Count": 71,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Reason: "EconomicProblems",
    "Death Count": 9,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Reason: "EmploymentProblems",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 13,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Reason: "Harrasment",
    "Death Count": 55,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Reason: "LoveAffairs",
    "Death Count": 69,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Reason: "Drugs",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Reason: "Mentaldisorders",
    "Death Count": 25,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 4,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Reason: "OtherReasons",
    "Death Count": 90,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Reason: "EconomicProblems",
    "Death Count": 17,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Reason: "EmploymentProblems",
    "Death Count": 5,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 7,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Reason: "Harrasment",
    "Death Count": 67,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Reason: "LoveAffairs",
    "Death Count": 39,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Reason: "Drugs",
    "Death Count": 11,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Reason: "Mentaldisorders",
    "Death Count": 23,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 6,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Reason: "OtherReasons",
    "Death Count": 86,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Reason: "EconomicProblems",
    "Death Count": 18,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Reason: "EmploymentProblems",
    "Death Count": 3,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 6,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Reason: "Harrasment",
    "Death Count": 79,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Reason: "LoveAffairs",
    "Death Count": 16,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Reason: "Drugs",
    "Death Count": 13,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Reason: "Mentaldisorders",
    "Death Count": 27,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 7,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Reason: "OtherReasons",
    "Death Count": 95,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Reason: "EconomicProblems",
    "Death Count": 21,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Reason: "EmploymentProblems",
    "Death Count": 4,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Reason: "Harrasment",
    "Death Count": 85,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Reason: "LoveAffairs",
    "Death Count": 12,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Reason: "Drugs",
    "Death Count": 7,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Reason: "Mentaldisorders",
    "Death Count": 19,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 14,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Reason: "OtherReasons",
    "Death Count": 96,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Reason: "EconomicProblems",
    "Death Count": 23,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Reason: "EmploymentProblems",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Reason: "Harrasment",
    "Death Count": 64,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Reason: "LoveAffairs",
    "Death Count": 5,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Reason: "Drugs",
    "Death Count": 14,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Reason: "Mentaldisorders",
    "Death Count": 29,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 15,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Reason: "OtherReasons",
    "Death Count": 75,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Reason: "EconomicProblems",
    "Death Count": 22,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Reason: "EmploymentProblems",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Reason: "Harrasment",
    "Death Count": 50,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Reason: "LoveAffairs",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Reason: "Drugs",
    "Death Count": 17,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Reason: "Mentaldisorders",
    "Death Count": 34,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 27,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Reason: "OtherReasons",
    "Death Count": 99,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Reason: "EconomicProblems",
    "Death Count": 18,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Reason: "EmploymentProblems",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Reason: "Harrasment",
    "Death Count": 68,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Reason: "LoveAffairs",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Reason: "Drugs",
    "Death Count": 14,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 4,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Reason: "LossOfProperty",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Reason: "Mentaldisorders",
    "Death Count": 25,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 41,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Reason: "OtherReasons",
    "Death Count": 109,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Reason: "EconomicProblems",
    "Death Count": 22,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Reason: "EmploymentProblems",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Reason: "Harrasment",
    "Death Count": 30,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Reason: "LoveAffairs",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Reason: "Drugs",
    "Death Count": 19,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Reason: "LossOfProperty",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Reason: "Mentaldisorders",
    "Death Count": 33,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 45,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Reason: "OtherReasons",
    "Death Count": 94,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Reason: "EconomicProblems",
    "Death Count": 12,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Reason: "EmploymentProblems",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Reason: "Harrasment",
    "Death Count": 24,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Reason: "LoveAffairs",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Reason: "Drugs",
    "Death Count": 9,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Reason: "Mentaldisorders",
    "Death Count": 29,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 61,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Reason: "OtherReasons",
    "Death Count": 82,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Reason: "EconomicProblems",
    "Death Count": 3,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Reason: "EmploymentProblems",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Reason: "Harrasment",
    "Death Count": 10,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Reason: "LoveAffairs",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Reason: "SexualHarrassment",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Reason: "Drugs",
    "Death Count": 7,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Reason: "Mentaldisorders",
    "Death Count": 14,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 52,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Reason: "OtherReasons",
    "Death Count": 55,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Reason: "EconomicProblems",
    "Death Count": 7,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Reason: "EmploymentProblems",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Reason: "Harrasment",
    "Death Count": 19,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Reason: "LoveAffairs",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Reason: "Drugs",
    "Death Count": 6,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 4,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Reason: "LossOfProperty",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Reason: "Mentaldisorders",
    "Death Count": 42,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 111,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Reason: "OtherReasons",
    "Death Count": 106,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Reason: "EconomicProblems",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Reason: "EmploymentProblems",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Reason: "Harrasment",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Reason: "LoveAffairs",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Reason: "Drugs",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Reason: "Mentaldisorders",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Reason: "OtherReasons",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Reason: "EconomicProblems",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Reason: "EmploymentProblems",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 20,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Reason: "Harrasment",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Reason: "LoveAffairs",
    "Death Count": 31,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Reason: "Drugs",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Reason: "FailureAtTheExamination",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Reason: "Mentaldisorders",
    "Death Count": 6,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Reason: "OtherReasons",
    "Death Count": 28,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Reason: "EconomicProblems",
    "Death Count": 6,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Reason: "EmploymentProblems",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 24,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Reason: "Harrasment",
    "Death Count": 15,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Reason: "LoveAffairs",
    "Death Count": 83,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Reason: "Drugs",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 5,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Reason: "FailureAtTheExamination",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Reason: "Mentaldisorders",
    "Death Count": 9,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 5,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Reason: "OtherReasons",
    "Death Count": 49,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Reason: "EconomicProblems",
    "Death Count": 11,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Reason: "EmploymentProblems",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 12,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Reason: "Harrasment",
    "Death Count": 58,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Reason: "LoveAffairs",
    "Death Count": 72,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Reason: "Drugs",
    "Death Count": 11,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Reason: "FailureAtTheExamination",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Reason: "Mentaldisorders",
    "Death Count": 20,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 14,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Reason: "OtherReasons",
    "Death Count": 92,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Reason: "EconomicProblems",
    "Death Count": 15,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Reason: "EmploymentProblems",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 5,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Reason: "Harrasment",
    "Death Count": 89,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Reason: "LoveAffairs",
    "Death Count": 50,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Reason: "Drugs",
    "Death Count": 16,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Reason: "Mentaldisorders",
    "Death Count": 29,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 13,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Reason: "OtherReasons",
    "Death Count": 73,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Reason: "EconomicProblems",
    "Death Count": 29,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Reason: "EmploymentProblems",
    "Death Count": 4,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Reason: "Harrasment",
    "Death Count": 87,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Reason: "LoveAffairs",
    "Death Count": 18,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Reason: "Drugs",
    "Death Count": 13,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 5,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Reason: "LossOfProperty",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Reason: "Mentaldisorders",
    "Death Count": 21,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 11,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Reason: "OtherReasons",
    "Death Count": 85,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Reason: "EconomicProblems",
    "Death Count": 19,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Reason: "EmploymentProblems",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Reason: "Harrasment",
    "Death Count": 77,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Reason: "LoveAffairs",
    "Death Count": 11,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Reason: "Drugs",
    "Death Count": 19,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Reason: "Mentaldisorders",
    "Death Count": 21,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 14,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Reason: "OtherReasons",
    "Death Count": 70,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Reason: "EconomicProblems",
    "Death Count": 19,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Reason: "EmploymentProblems",
    "Death Count": 5,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Reason: "Harrasment",
    "Death Count": 70,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Reason: "LoveAffairs",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Reason: "Drugs",
    "Death Count": 18,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Reason: "LossOfProperty",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Reason: "Mentaldisorders",
    "Death Count": 20,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 17,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Reason: "OtherReasons",
    "Death Count": 79,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Reason: "EconomicProblems",
    "Death Count": 23,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Reason: "EmploymentProblems",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Reason: "Harrasment",
    "Death Count": 71,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Reason: "LoveAffairs",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Reason: "Drugs",
    "Death Count": 25,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Reason: "LossOfProperty",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Reason: "Mentaldisorders",
    "Death Count": 25,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 21,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Reason: "OtherReasons",
    "Death Count": 93,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Reason: "EconomicProblems",
    "Death Count": 27,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Reason: "EmploymentProblems",
    "Death Count": 4,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Reason: "Harrasment",
    "Death Count": 48,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Reason: "LoveAffairs",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Reason: "Drugs",
    "Death Count": 10,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Reason: "LossOfProperty",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Reason: "Mentaldisorders",
    "Death Count": 29,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 44,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Reason: "OtherReasons",
    "Death Count": 90,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Reason: "EconomicProblems",
    "Death Count": 26,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Reason: "EmploymentProblems",
    "Death Count": 9,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Reason: "Harrasment",
    "Death Count": 35,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Reason: "LoveAffairs",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Reason: "Drugs",
    "Death Count": 20,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Reason: "LossOfProperty",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Reason: "Mentaldisorders",
    "Death Count": 31,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 38,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Reason: "OtherReasons",
    "Death Count": 93,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Reason: "EconomicProblems",
    "Death Count": 13,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Reason: "EmploymentProblems",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Reason: "Harrasment",
    "Death Count": 25,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Reason: "LoveAffairs",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Reason: "Drugs",
    "Death Count": 7,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 6,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Reason: "LossOfProperty",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Reason: "Mentaldisorders",
    "Death Count": 24,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 56,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Reason: "OtherReasons",
    "Death Count": 97,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Reason: "EconomicProblems",
    "Death Count": 8,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Reason: "EmploymentProblems",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Reason: "Harrasment",
    "Death Count": 16,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Reason: "LoveAffairs",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Reason: "Drugs",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Reason: "LossOfProperty",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Reason: "Mentaldisorders",
    "Death Count": 17,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 53,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Reason: "OtherReasons",
    "Death Count": 60,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Reason: "EconomicProblems",
    "Death Count": 8,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Reason: "EmploymentProblems",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Reason: "ProblemsCausedWithTheElders",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Reason: "Harrasment",
    "Death Count": 17,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Reason: "LoveAffairs",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Reason: "SexualHarrassment",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Reason: "Drugs",
    "Death Count": 4,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Reason: "AggrievedOverTheDeathParents",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Reason: "LossOfProperty",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Reason: "FailureAtTheExamination",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Reason: "Mentaldisorders",
    "Death Count": 43,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Reason: "ChronicDiseasesPhysicalDisabilities",
    "Death Count": 131,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Reason: "OtherReasons",
    "Death Count": 110,
    Year: 2014,
  },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "EconomicProblems",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "Harrasment",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "LoveAffairs",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "Drugs",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "LossOfProperty",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "OtherReasons",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "EconomicProblems",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 13,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "Harrasment",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "LoveAffairs",
  //     "Death Count": 18,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "Drugs",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "LossOfProperty",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 8,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "OtherReasons",
  //     "Death Count": 25,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "EconomicProblems",
  //     "Death Count": 3,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 30,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "Harrasment",
  //     "Death Count": 12,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "LoveAffairs",
  //     "Death Count": 93,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "Drugs",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 4,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "LossOfProperty",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 10,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 3,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "OtherReasons",
  //     "Death Count": 63,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "EconomicProblems",
  //     "Death Count": 10,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 10,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "Harrasment",
  //     "Death Count": 48,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "LoveAffairs",
  //     "Death Count": 79,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "Drugs",
  //     "Death Count": 10,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "LossOfProperty",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 22,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 12,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "OtherReasons",
  //     "Death Count": 80,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "EconomicProblems",
  //     "Death Count": 13,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 7,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "Harrasment",
  //     "Death Count": 85,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "LoveAffairs",
  //     "Death Count": 63,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "Drugs",
  //     "Death Count": 20,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "LossOfProperty",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 26,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 10,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "OtherReasons",
  //     "Death Count": 59,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "EconomicProblems",
  //     "Death Count": 32,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "Harrasment",
  //     "Death Count": 96,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "LoveAffairs",
  //     "Death Count": 15,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "Drugs",
  //     "Death Count": 11,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 3,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "LossOfProperty",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 26,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 9,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "OtherReasons",
  //     "Death Count": 78,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "EconomicProblems",
  //     "Death Count": 16,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 13,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "Harrasment",
  //     "Death Count": 65,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "LoveAffairs",
  //     "Death Count": 8,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "Drugs",
  //     "Death Count": 18,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "LossOfProperty",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 18,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 13,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "OtherReasons",
  //     "Death Count": 68,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "EconomicProblems",
  //     "Death Count": 30,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "Harrasment",
  //     "Death Count": 73,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "LoveAffairs",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "Drugs",
  //     "Death Count": 13,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "LossOfProperty",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 17,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 12,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "OtherReasons",
  //     "Death Count": 85,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "EconomicProblems",
  //     "Death Count": 20,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "Harrasment",
  //     "Death Count": 83,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "LoveAffairs",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "Drugs",
  //     "Death Count": 19,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "LossOfProperty",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 22,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 19,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "OtherReasons",
  //     "Death Count": 89,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "EconomicProblems",
  //     "Death Count": 18,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 4,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "Harrasment",
  //     "Death Count": 70,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "LoveAffairs",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "Drugs",
  //     "Death Count": 15,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "LossOfProperty",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 25,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 53,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "OtherReasons",
  //     "Death Count": 76,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "EconomicProblems",
  //     "Death Count": 14,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 5,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "Harrasment",
  //     "Death Count": 42,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "LoveAffairs",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "Drugs",
  //     "Death Count": 19,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "LossOfProperty",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 37,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 40,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "OtherReasons",
  //     "Death Count": 86,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "EconomicProblems",
  //     "Death Count": 12,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "Harrasment",
  //     "Death Count": 26,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "LoveAffairs",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "Drugs",
  //     "Death Count": 8,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 3,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "LossOfProperty",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 20,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 52,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "OtherReasons",
  //     "Death Count": 90,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "EconomicProblems",
  //     "Death Count": 9,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "Harrasment",
  //     "Death Count": 18,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "LoveAffairs",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "Drugs",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "LossOfProperty",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 20,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 55,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "OtherReasons",
  //     "Death Count": 75,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "EconomicProblems",
  //     "Death Count": 5,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "Harrasment",
  //     "Death Count": 18,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "LoveAffairs",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "Drugs",
  //     "Death Count": 3,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "LossOfProperty",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 46,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 125,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "OtherReasons",
  //     "Death Count": 105,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "EconomicProblems",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "Harrasment",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "LoveAffairs",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "Drugs",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "LossOfProperty",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "OtherReasons",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "EconomicProblems",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 19,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "Harrasment",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "LoveAffairs",
  //     "Death Count": 35,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "Drugs",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "LossOfProperty",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 3,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "OtherReasons",
  //     "Death Count": 22,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "EconomicProblems",
  //     "Death Count": 6,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 32,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "Harrasment",
  //     "Death Count": 13,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "LoveAffairs",
  //     "Death Count": 88,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "Drugs",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "LossOfProperty",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 5,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 4,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "OtherReasons",
  //     "Death Count": 52,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "EconomicProblems",
  //     "Death Count": 12,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 15,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "Harrasment",
  //     "Death Count": 48,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "LoveAffairs",
  //     "Death Count": 59,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "Drugs",
  //     "Death Count": 14,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "LossOfProperty",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 22,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 10,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "OtherReasons",
  //     "Death Count": 89,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "EconomicProblems",
  //     "Death Count": 17,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 6,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "Harrasment",
  //     "Death Count": 85,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "LoveAffairs",
  //     "Death Count": 46,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "Drugs",
  //     "Death Count": 13,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "LossOfProperty",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 22,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 10,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "OtherReasons",
  //     "Death Count": 80,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "EconomicProblems",
  //     "Death Count": 22,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 3,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "Harrasment",
  //     "Death Count": 90,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "LoveAffairs",
  //     "Death Count": 16,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "Drugs",
  //     "Death Count": 15,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "LossOfProperty",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 28,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 8,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "OtherReasons",
  //     "Death Count": 79,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "EconomicProblems",
  //     "Death Count": 23,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "Harrasment",
  //     "Death Count": 83,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "LoveAffairs",
  //     "Death Count": 13,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "Drugs",
  //     "Death Count": 16,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "LossOfProperty",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 18,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 10,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "OtherReasons",
  //     "Death Count": 73,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "EconomicProblems",
  //     "Death Count": 20,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "Harrasment",
  //     "Death Count": 78,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "LoveAffairs",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "Drugs",
  //     "Death Count": 21,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "LossOfProperty",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 16,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 13,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "OtherReasons",
  //     "Death Count": 81,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "EconomicProblems",
  //     "Death Count": 20,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "Harrasment",
  //     "Death Count": 56,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "LoveAffairs",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "Drugs",
  //     "Death Count": 19,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "LossOfProperty",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 22,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 28,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "OtherReasons",
  //     "Death Count": 96,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "EconomicProblems",
  //     "Death Count": 25,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "Harrasment",
  //     "Death Count": 37,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "LoveAffairs",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "Drugs",
  //     "Death Count": 12,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "LossOfProperty",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 41,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 32,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "OtherReasons",
  //     "Death Count": 107,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "EconomicProblems",
  //     "Death Count": 22,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 4,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 3,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "Harrasment",
  //     "Death Count": 46,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "LoveAffairs",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "Drugs",
  //     "Death Count": 19,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 4,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "LossOfProperty",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 29,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 39,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "OtherReasons",
  //     "Death Count": 90,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "EconomicProblems",
  //     "Death Count": 11,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "Harrasment",
  //     "Death Count": 17,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "LoveAffairs",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "Drugs",
  //     "Death Count": 11,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 3,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "LossOfProperty",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 36,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 51,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "OtherReasons",
  //     "Death Count": 101,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "EconomicProblems",
  //     "Death Count": 9,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "Harrasment",
  //     "Death Count": 11,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "LoveAffairs",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "Drugs",
  //     "Death Count": 5,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "LossOfProperty",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 14,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 65,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "OtherReasons",
  //     "Death Count": 60,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "EconomicProblems",
  //     "Death Count": 6,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "Harrasment",
  //     "Death Count": 15,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "LoveAffairs",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "Drugs",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "LossOfProperty",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 40,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 142,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "OtherReasons",
  //     "Death Count": 105,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "EconomicProblems",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "Harrasment",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "LoveAffairs",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "Drugs",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "LossOfProperty",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     Reason: "OtherReasons",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "EconomicProblems",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 16,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "Harrasment",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "LoveAffairs",
  //     "Death Count": 29,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "Drugs",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "LossOfProperty",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 3,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 5,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     Reason: "OtherReasons",
  //     "Death Count": 18,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "EconomicProblems",
  //     "Death Count": 2,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 49,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "Harrasment",
  //     "Death Count": 10,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "LoveAffairs",
  //     "Death Count": 85,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "Drugs",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 2,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "LossOfProperty",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 8,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 5,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     Reason: "OtherReasons",
  //     "Death Count": 57,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "EconomicProblems",
  //     "Death Count": 10,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 3,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 12,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "Harrasment",
  //     "Death Count": 40,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "LoveAffairs",
  //     "Death Count": 72,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "Drugs",
  //     "Death Count": 14,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "LossOfProperty",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 20,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 11,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     Reason: "OtherReasons",
  //     "Death Count": 87,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "EconomicProblems",
  //     "Death Count": 15,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 10,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "Harrasment",
  //     "Death Count": 82,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "LoveAffairs",
  //     "Death Count": 57,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "Drugs",
  //     "Death Count": 18,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "LossOfProperty",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 20,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 5,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     Reason: "OtherReasons",
  //     "Death Count": 59,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "EconomicProblems",
  //     "Death Count": 30,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 2,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "Harrasment",
  //     "Death Count": 93,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "LoveAffairs",
  //     "Death Count": 20,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "Drugs",
  //     "Death Count": 9,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "LossOfProperty",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 25,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 5,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     Reason: "OtherReasons",
  //     "Death Count": 65,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "EconomicProblems",
  //     "Death Count": 18,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 5,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "Harrasment",
  //     "Death Count": 70,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "LoveAffairs",
  //     "Death Count": 9,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "Drugs",
  //     "Death Count": 13,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "LossOfProperty",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 15,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 11,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     Reason: "OtherReasons",
  //     "Death Count": 61,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "EconomicProblems",
  //     "Death Count": 19,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 3,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "Harrasment",
  //     "Death Count": 70,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "LoveAffairs",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "Drugs",
  //     "Death Count": 22,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "LossOfProperty",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 15,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 16,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     Reason: "OtherReasons",
  //     "Death Count": 78,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "EconomicProblems",
  //     "Death Count": 17,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "Harrasment",
  //     "Death Count": 51,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "LoveAffairs",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "Drugs",
  //     "Death Count": 21,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "LossOfProperty",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 20,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 27,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     Reason: "OtherReasons",
  //     "Death Count": 93,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "EconomicProblems",
  //     "Death Count": 20,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "Harrasment",
  //     "Death Count": 33,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "LoveAffairs",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "Drugs",
  //     "Death Count": 10,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "LossOfProperty",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 38,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 35,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     Reason: "OtherReasons",
  //     "Death Count": 98,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "EconomicProblems",
  //     "Death Count": 19,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "Harrasment",
  //     "Death Count": 40,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "LoveAffairs",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "Drugs",
  //     "Death Count": 18,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 3,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "LossOfProperty",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 42,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 49,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     Reason: "OtherReasons",
  //     "Death Count": 98,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "EconomicProblems",
  //     "Death Count": 12,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "Harrasment",
  //     "Death Count": 22,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "LoveAffairs",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "Drugs",
  //     "Death Count": 9,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 5,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "LossOfProperty",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 27,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 55,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     Reason: "OtherReasons",
  //     "Death Count": 110,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "EconomicProblems",
  //     "Death Count": 10,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "Harrasment",
  //     "Death Count": 16,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "LoveAffairs",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "Drugs",
  //     "Death Count": 3,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "LossOfProperty",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 19,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 63,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     Reason: "OtherReasons",
  //     "Death Count": 68,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "EconomicProblems",
  //     "Death Count": 3,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "EmploymentProblems",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "ProblemsCausedWithTheElders",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "Harrasment",
  //     "Death Count": 13,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "LoveAffairs",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "SexualHarrassment",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "Drugs",
  //     "Death Count": 3,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "AggrievedOverTheDeathParents",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "LossOfProperty",
  //     "Death Count": 3,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "FailureAtTheExamination",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "Mentaldisorders",
  //     "Death Count": 47,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "ChronicDiseasesPhysicalDisabilities",
  //     "Death Count": 152,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     Reason: "OtherReasons",
  //     "Death Count": 115,
  //     Year: 2011,
  //   },
];
