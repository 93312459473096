export const mydata = [
  {
    "Age Group": "0-8",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Method: "Strangling",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Method: "JumpingIntoTheWater",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Method: "UsingFirearms",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Method: "SettingFireToOneself",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Method: "DrinkingTheAcides",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Method: "Other",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 10,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Method: "Strangling",
    "Death Count": 47,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Method: "JumpingIntoTheWater",
    "Death Count": 8,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Method: "UsingFirearms",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Method: "SettingFireToOneself",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Method: "DrinkingTheAcides",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    Method: "Other",
    "Death Count": 4,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 42,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Method: "Strangling",
    "Death Count": 129,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Method: "JumpingIntoTheWater",
    "Death Count": 10,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Method: "UsingFirearms",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Method: "SettingFireToOneself",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 18,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Method: "DrinkingTheAcides",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 6,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 4,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    Method: "Other",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 42,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Method: "Strangling",
    "Death Count": 150,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Method: "JumpingIntoTheWater",
    "Death Count": 11,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Method: "UsingFirearms",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Method: "SettingFireToOneself",
    "Death Count": 25,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 20,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Method: "DrinkingTheAcides",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 9,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 12,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    Method: "Other",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 72,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Method: "Strangling",
    "Death Count": 165,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Method: "JumpingIntoTheWater",
    "Death Count": 15,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Method: "UsingFirearms",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Method: "UsingSharpWeapons",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Method: "SettingFireToOneself",
    "Death Count": 20,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 13,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Method: "DrinkingTheAcides",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 6,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    Method: "Other",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 54,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Method: "Strangling",
    "Death Count": 122,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Method: "JumpingIntoTheWater",
    "Death Count": 12,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Method: "UsingFirearms",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Method: "UsingSharpWeapons",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Method: "SettingFireToOneself",
    "Death Count": 18,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 22,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Method: "DrinkingTheAcides",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 7,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    Method: "Other",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 79,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Method: "Strangling",
    "Death Count": 158,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Method: "JumpingIntoTheWater",
    "Death Count": 6,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Method: "UsingFirearms",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Method: "UsingSharpWeapons",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Method: "SettingFireToOneself",
    "Death Count": 15,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 19,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Method: "DrinkingTheAcides",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Method: "DrinkingTheFuel",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 14,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    Method: "Other",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 75,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Method: "Strangling",
    "Death Count": 117,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Method: "JumpingIntoTheWater",
    "Death Count": 13,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Method: "UsingFirearms",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Method: "UsingSharpWeapons",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Method: "SettingFireToOneself",
    "Death Count": 21,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 13,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Method: "DrinkingTheAcides",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 4,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    Method: "Other",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 100,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Method: "Strangling",
    "Death Count": 112,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Method: "JumpingIntoTheWater",
    "Death Count": 6,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Method: "UsingFirearms",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Method: "UsingSharpWeapons",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Method: "SettingFireToOneself",
    "Death Count": 17,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 19,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Method: "DrinkingTheAcides",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 4,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    Method: "Other",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 125,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Method: "Strangling",
    "Death Count": 116,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Method: "JumpingIntoTheWater",
    "Death Count": 16,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Method: "UsingFirearms",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Method: "SettingFireToOneself",
    "Death Count": 10,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 15,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Method: "DrinkingTheAcides",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    Method: "Other",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 120,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Method: "Strangling",
    "Death Count": 105,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Method: "JumpingIntoTheWater",
    "Death Count": 9,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Method: "UsingFirearms",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Method: "UsingSharpWeapons",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Method: "SettingFireToOneself",
    "Death Count": 10,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 7,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Method: "DrinkingTheAcides",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 6,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    Method: "Other",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 56,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Method: "Strangling",
    "Death Count": 148,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Method: "JumpingIntoTheWater",
    "Death Count": 10,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Method: "UsingFirearms",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Method: "SettingFireToOneself",
    "Death Count": 6,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 13,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Method: "DrinkingTheAcides",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 8,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    Method: "Other",
    "Death Count": 4,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 61,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Method: "Strangling",
    "Death Count": 86,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Method: "JumpingIntoTheWater",
    "Death Count": 8,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Method: "UsingFirearms",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Method: "UsingSharpWeapons",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Method: "SettingFireToOneself",
    "Death Count": 10,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 9,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Method: "DrinkingTheAcides",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    Method: "Other",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 100,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Method: "Strangling",
    "Death Count": 167,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Method: "JumpingIntoTheWater",
    "Death Count": 17,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Method: "UsingFirearms",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Method: "SettingFireToOneself",
    "Death Count": 13,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 10,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Method: "DrinkingTheAcides",
    "Death Count": 8,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 8,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    Method: "Other",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Method: "Strangling",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Method: "JumpingIntoTheWater",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Method: "UsingFirearms",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Method: "SettingFireToOneself",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Method: "DrinkingTheAcides",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Method: "Other",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 11,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Method: "Strangling",
    "Death Count": 65,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Method: "JumpingIntoTheWater",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Method: "UsingFirearms",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Method: "SettingFireToOneself",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Method: "DrinkingTheAcides",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    Method: "Other",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 36,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Method: "Strangling",
    "Death Count": 137,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Method: "JumpingIntoTheWater",
    "Death Count": 7,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Method: "UsingFirearms",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Method: "SettingFireToOneself",
    "Death Count": 7,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 9,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Method: "DrinkingTheAcides",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 5,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    Method: "Other",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 45,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Method: "Strangling",
    "Death Count": 182,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Method: "JumpingIntoTheWater",
    "Death Count": 7,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Method: "UsingFirearms",
    "Death Count": 4,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Method: "SettingFireToOneself",
    "Death Count": 12,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 12,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Method: "DrinkingTheAcides",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 9,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    Method: "Other",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 56,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Method: "Strangling",
    "Death Count": 184,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Method: "JumpingIntoTheWater",
    "Death Count": 9,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Method: "UsingFirearms",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Method: "UsingSharpWeapons",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Method: "SettingFireToOneself",
    "Death Count": 15,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 17,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Method: "DrinkingTheAcides",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 6,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    Method: "Other",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 60,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Method: "Strangling",
    "Death Count": 167,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Method: "JumpingIntoTheWater",
    "Death Count": 8,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Method: "UsingFirearms",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Method: "UsingSharpWeapons",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Method: "SettingFireToOneself",
    "Death Count": 20,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 17,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Method: "DrinkingTheAcides",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Method: "DrinkingTheFuel",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 4,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    Method: "Other",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 49,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Method: "Strangling",
    "Death Count": 147,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Method: "JumpingIntoTheWater",
    "Death Count": 7,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Method: "UsingFirearms",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Method: "UsingSharpWeapons",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Method: "SettingFireToOneself",
    "Death Count": 13,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 14,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Method: "DrinkingTheAcides",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 4,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 7,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 4,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    Method: "Other",
    "Death Count": 4,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 75,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Method: "Strangling",
    "Death Count": 145,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Method: "JumpingIntoTheWater",
    "Death Count": 5,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Method: "UsingFirearms",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Method: "UsingSharpWeapons",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Method: "SettingFireToOneself",
    "Death Count": 7,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 19,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Method: "DrinkingTheAcides",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    Method: "Other",
    "Death Count": 5,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 91,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Method: "Strangling",
    "Death Count": 136,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Method: "JumpingIntoTheWater",
    "Death Count": 6,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Method: "UsingFirearms",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Method: "UsingSharpWeapons",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Method: "SettingFireToOneself",
    "Death Count": 6,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 13,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Method: "DrinkingTheAcides",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 6,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    Method: "Other",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 95,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Method: "Strangling",
    "Death Count": 141,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Method: "JumpingIntoTheWater",
    "Death Count": 9,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Method: "UsingFirearms",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Method: "SettingFireToOneself",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 12,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Method: "DrinkingTheAcides",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 7,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    Method: "Other",
    "Death Count": 6,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 103,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Method: "Strangling",
    "Death Count": 129,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Method: "JumpingIntoTheWater",
    "Death Count": 10,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Method: "UsingFirearms",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Method: "SettingFireToOneself",
    "Death Count": 9,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 17,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Method: "DrinkingTheAcides",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 9,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    Method: "Other",
    "Death Count": 5,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 76,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Method: "Strangling",
    "Death Count": 132,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Method: "JumpingIntoTheWater",
    "Death Count": 9,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Method: "UsingFirearms",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Method: "SettingFireToOneself",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 11,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Method: "DrinkingTheAcides",
    "Death Count": 9,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 5,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    Method: "Other",
    "Death Count": 4,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 56,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Method: "Strangling",
    "Death Count": 93,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Method: "JumpingIntoTheWater",
    "Death Count": 7,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Method: "UsingFirearms",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Method: "UsingSharpWeapons",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Method: "SettingFireToOneself",
    "Death Count": 5,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 7,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Method: "DrinkingTheAcides",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Method: "DrinkingTheFuel",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    Method: "Other",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 99,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Method: "Strangling",
    "Death Count": 156,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Method: "JumpingIntoTheWater",
    "Death Count": 22,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Method: "UsingFirearms",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Method: "SettingFireToOneself",
    "Death Count": 14,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 12,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Method: "DrinkingTheAcides",
    "Death Count": 9,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    Method: "Other",
    "Death Count": 6,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Method: "Strangling",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Method: "JumpingIntoTheWater",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Method: "UsingFirearms",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Method: "SettingFireToOneself",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Method: "DrinkingTheAcides",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Method: "Other",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 8,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Method: "Strangling",
    "Death Count": 54,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Method: "JumpingIntoTheWater",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Method: "UsingFirearms",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Method: "SettingFireToOneself",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Method: "DrinkingTheAcides",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 4,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 8,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    Method: "Other",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 37,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Method: "Strangling",
    "Death Count": 139,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Method: "JumpingIntoTheWater",
    "Death Count": 12,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Method: "UsingFirearms",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Method: "SettingFireToOneself",
    "Death Count": 8,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 5,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Method: "DrinkingTheAcides",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 4,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 8,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    Method: "Other",
    "Death Count": 4,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 63,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Method: "Strangling",
    "Death Count": 171,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Method: "JumpingIntoTheWater",
    "Death Count": 9,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Method: "UsingFirearms",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Method: "SettingFireToOneself",
    "Death Count": 20,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 18,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Method: "DrinkingTheAcides",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 7,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    Method: "Other",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 47,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Method: "Strangling",
    "Death Count": 138,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Method: "JumpingIntoTheWater",
    "Death Count": 7,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Method: "UsingFirearms",
    "Death Count": 5,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Method: "SettingFireToOneself",
    "Death Count": 11,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 12,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Method: "DrinkingTheAcides",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Method: "DrinkingTheFuel",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 7,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    Method: "Other",
    "Death Count": 5,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 76,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Method: "Strangling",
    "Death Count": 123,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Method: "JumpingIntoTheWater",
    "Death Count": 9,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Method: "UsingFirearms",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Method: "SettingFireToOneself",
    "Death Count": 13,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 16,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Method: "DrinkingTheAcides",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    Method: "Other",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 57,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Method: "Strangling",
    "Death Count": 129,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Method: "JumpingIntoTheWater",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Method: "UsingFirearms",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Method: "UsingSharpWeapons",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Method: "SettingFireToOneself",
    "Death Count": 9,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 9,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Method: "DrinkingTheAcides",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 7,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    Method: "Other",
    "Death Count": 4,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 60,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Method: "Strangling",
    "Death Count": 130,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Method: "JumpingIntoTheWater",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Method: "UsingFirearms",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Method: "UsingSharpWeapons",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Method: "SettingFireToOneself",
    "Death Count": 7,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 12,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Method: "DrinkingTheAcides",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 6,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    Method: "Other",
    "Death Count": 6,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 70,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Method: "Strangling",
    "Death Count": 110,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Method: "JumpingIntoTheWater",
    "Death Count": 9,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Method: "UsingFirearms",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Method: "UsingSharpWeapons",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Method: "SettingFireToOneself",
    "Death Count": 8,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 14,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Method: "DrinkingTheAcides",
    "Death Count": 4,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 14,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    Method: "Other",
    "Death Count": 8,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 106,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Method: "Strangling",
    "Death Count": 134,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Method: "JumpingIntoTheWater",
    "Death Count": 11,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Method: "UsingFirearms",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Method: "SettingFireToOneself",
    "Death Count": 9,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 8,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Method: "DrinkingTheAcides",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 5,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    Method: "Other",
    "Death Count": 5,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 90,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Method: "Strangling",
    "Death Count": 111,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Method: "JumpingIntoTheWater",
    "Death Count": 4,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Method: "UsingFirearms",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Method: "SettingFireToOneself",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 8,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Method: "DrinkingTheAcides",
    "Death Count": 4,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 5,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    Method: "Other",
    "Death Count": 10,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 72,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Method: "Strangling",
    "Death Count": 87,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Method: "JumpingIntoTheWater",
    "Death Count": 5,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Method: "UsingFirearms",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Method: "SettingFireToOneself",
    "Death Count": 7,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 12,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Method: "DrinkingTheAcides",
    "Death Count": 7,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Method: "DrinkingTheFuel",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    Method: "Other",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 77,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Method: "Strangling",
    "Death Count": 82,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Method: "JumpingIntoTheWater",
    "Death Count": 4,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Method: "UsingFirearms",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Method: "SettingFireToOneself",
    "Death Count": 7,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 8,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Method: "DrinkingTheAcides",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 5,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    Method: "Other",
    "Death Count": 11,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 110,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Method: "Strangling",
    "Death Count": 165,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Method: "JumpingIntoTheWater",
    "Death Count": 18,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Method: "UsingFirearms",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Method: "SettingFireToOneself",
    "Death Count": 13,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 13,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Method: "DrinkingTheAcides",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    Method: "Other",
    "Death Count": 9,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Method: "Strangling",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Method: "JumpingIntoTheWater",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Method: "UsingFirearms",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Method: "SettingFireToOneself",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Method: "DrinkingTheAcides",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Method: "Other",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 6,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Method: "Strangling",
    "Death Count": 43,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Method: "JumpingIntoTheWater",
    "Death Count": 7,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Method: "UsingFirearms",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Method: "SettingFireToOneself",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Method: "DrinkingTheAcides",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 3,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    Method: "Other",
    "Death Count": 3,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 38,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Method: "Strangling",
    "Death Count": 138,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Method: "JumpingIntoTheWater",
    "Death Count": 12,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Method: "UsingFirearms",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Method: "SettingFireToOneself",
    "Death Count": 8,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 9,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Method: "DrinkingTheAcides",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 5,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 15,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 3,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    Method: "Other",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 47,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Method: "Strangling",
    "Death Count": 148,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Method: "JumpingIntoTheWater",
    "Death Count": 8,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Method: "UsingFirearms",
    "Death Count": 6,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Method: "UsingSharpWeapons",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Method: "SettingFireToOneself",
    "Death Count": 21,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 18,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Method: "DrinkingTheAcides",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 4,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 10,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    Method: "Other",
    "Death Count": 4,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 64,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Method: "Strangling",
    "Death Count": 140,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Method: "JumpingIntoTheWater",
    "Death Count": 13,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Method: "UsingFirearms",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Method: "SettingFireToOneself",
    "Death Count": 22,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 10,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Method: "DrinkingTheAcides",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 3,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    Method: "Other",
    "Death Count": 5,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 66,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Method: "Strangling",
    "Death Count": 133,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Method: "JumpingIntoTheWater",
    "Death Count": 10,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Method: "UsingFirearms",
    "Death Count": 4,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Method: "SettingFireToOneself",
    "Death Count": 21,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 16,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Method: "DrinkingTheAcides",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Method: "DrinkingTheFuel",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 9,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    Method: "Other",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 74,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Method: "Strangling",
    "Death Count": 137,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Method: "JumpingIntoTheWater",
    "Death Count": 5,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Method: "UsingFirearms",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Method: "UsingSharpWeapons",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Method: "SettingFireToOneself",
    "Death Count": 10,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 16,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Method: "DrinkingTheAcides",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 3,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 11,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    Method: "Other",
    "Death Count": 3,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 69,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Method: "Strangling",
    "Death Count": 113,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Method: "JumpingIntoTheWater",
    "Death Count": 10,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Method: "UsingFirearms",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Method: "SettingFireToOneself",
    "Death Count": 15,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 10,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Method: "DrinkingTheAcides",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 6,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    Method: "Other",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 91,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Method: "Strangling",
    "Death Count": 109,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Method: "JumpingIntoTheWater",
    "Death Count": 8,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Method: "UsingFirearms",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Method: "SettingFireToOneself",
    "Death Count": 12,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 14,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Method: "DrinkingTheAcides",
    "Death Count": 3,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Method: "DrinkingTheFuel",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 5,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 6,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    Method: "Other",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 116,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Method: "Strangling",
    "Death Count": 124,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Method: "JumpingIntoTheWater",
    "Death Count": 14,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Method: "UsingFirearms",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Method: "UsingSharpWeapons",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Method: "SettingFireToOneself",
    "Death Count": 7,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 10,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Method: "DrinkingTheAcides",
    "Death Count": 3,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 6,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    Method: "Other",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 110,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Method: "Strangling",
    "Death Count": 106,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Method: "JumpingIntoTheWater",
    "Death Count": 7,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Method: "UsingFirearms",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Method: "SettingFireToOneself",
    "Death Count": 6,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 5,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Method: "DrinkingTheAcides",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 4,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 3,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    Method: "Other",
    "Death Count": 5,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 82,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Method: "Strangling",
    "Death Count": 106,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Method: "JumpingIntoTheWater",
    "Death Count": 8,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Method: "UsingFirearms",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Method: "SettingFireToOneself",
    "Death Count": 3,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 9,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Method: "DrinkingTheAcides",
    "Death Count": 4,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Method: "DrinkingTheFuel",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 4,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    Method: "Other",
    "Death Count": 4,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 56,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Method: "Strangling",
    "Death Count": 65,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Method: "JumpingIntoTheWater",
    "Death Count": 8,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Method: "UsingFirearms",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Method: "SettingFireToOneself",
    "Death Count": 5,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 8,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Method: "DrinkingTheAcides",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Method: "DrinkingTheFuel",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    Method: "Other",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 98,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Method: "Strangling",
    "Death Count": 152,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Method: "JumpingIntoTheWater",
    "Death Count": 17,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Method: "UsingFirearms",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Method: "UsingSharpWeapons",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Method: "SettingFireToOneself",
    "Death Count": 14,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 5,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Method: "DrinkingTheAcides",
    "Death Count": 7,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Method: "DrinkingTheFuel",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 3,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    Method: "Other",
    "Death Count": 7,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Method: "Strangling",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Method: "JumpingIntoTheWater",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Method: "UsingFirearms",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Method: "SettingFireToOneself",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Method: "DrinkingTheAcides",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    Method: "Other",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Method: "Strangling",
    "Death Count": 38,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Method: "JumpingIntoTheWater",
    "Death Count": 6,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Method: "UsingFirearms",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Method: "SettingFireToOneself",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Method: "DrinkingTheAcides",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Method: "DrinkingTheFuel",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    Method: "Other",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 42,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Method: "Strangling",
    "Death Count": 80,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Method: "JumpingIntoTheWater",
    "Death Count": 9,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Method: "UsingFirearms",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Method: "SettingFireToOneself",
    "Death Count": 10,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 6,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Method: "DrinkingTheAcides",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 6,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 11,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    Method: "Other",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 57,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Method: "Strangling",
    "Death Count": 163,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Method: "JumpingIntoTheWater",
    "Death Count": 6,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Method: "UsingFirearms",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Method: "SettingFireToOneself",
    "Death Count": 16,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 14,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Method: "DrinkingTheAcides",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 9,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    Method: "Other",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 46,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Method: "Strangling",
    "Death Count": 128,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Method: "JumpingIntoTheWater",
    "Death Count": 11,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Method: "UsingFirearms",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Method: "SettingFireToOneself",
    "Death Count": 17,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 13,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Method: "DrinkingTheAcides",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Method: "DrinkingTheFuel",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 4,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    Method: "Other",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 72,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Method: "Strangling",
    "Death Count": 135,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Method: "JumpingIntoTheWater",
    "Death Count": 7,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Method: "UsingFirearms",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Method: "UsingSharpWeapons",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Method: "SettingFireToOneself",
    "Death Count": 14,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 15,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Method: "DrinkingTheAcides",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 4,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 9,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    Method: "Other",
    "Death Count": 4,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 82,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Method: "Strangling",
    "Death Count": 146,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Method: "JumpingIntoTheWater",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Method: "UsingFirearms",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Method: "SettingFireToOneself",
    "Death Count": 12,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 13,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Method: "DrinkingTheAcides",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Method: "DrinkingTheFuel",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 8,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    Method: "Other",
    "Death Count": 5,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 57,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Method: "Strangling",
    "Death Count": 134,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Method: "JumpingIntoTheWater",
    "Death Count": 4,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Method: "UsingFirearms",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Method: "UsingSharpWeapons",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Method: "SettingFireToOneself",
    "Death Count": 13,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 17,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Method: "DrinkingTheAcides",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 4,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    Method: "Other",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 86,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Method: "Strangling",
    "Death Count": 123,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Method: "JumpingIntoTheWater",
    "Death Count": 5,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Method: "UsingFirearms",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Method: "UsingSharpWeapons",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Method: "SettingFireToOneself",
    "Death Count": 15,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 16,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Method: "DrinkingTheAcides",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 9,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    Method: "Other",
    "Death Count": 6,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 120,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Method: "Strangling",
    "Death Count": 147,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Method: "JumpingIntoTheWater",
    "Death Count": 13,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Method: "UsingFirearms",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Method: "SettingFireToOneself",
    "Death Count": 5,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 14,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Method: "DrinkingTheAcides",
    "Death Count": 5,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 4,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    Method: "Other",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 98,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Method: "Strangling",
    "Death Count": 140,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Method: "JumpingIntoTheWater",
    "Death Count": 12,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Method: "UsingFirearms",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Method: "UsingSharpWeapons",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Method: "SettingFireToOneself",
    "Death Count": 12,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 5,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Method: "DrinkingTheAcides",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 10,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    Method: "Other",
    "Death Count": 6,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 87,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Method: "Strangling",
    "Death Count": 104,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Method: "JumpingIntoTheWater",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Method: "UsingFirearms",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Method: "SettingFireToOneself",
    "Death Count": 6,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 6,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Method: "DrinkingTheAcides",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 6,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    Method: "Other",
    "Death Count": 6,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 47,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Method: "Strangling",
    "Death Count": 90,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Method: "JumpingIntoTheWater",
    "Death Count": 6,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Method: "UsingFirearms",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Method: "SettingFireToOneself",
    "Death Count": 4,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 5,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Method: "DrinkingTheAcides",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 7,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    Method: "Other",
    "Death Count": 7,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Method: "DrinkingInsecticidesAndPecticides",
    "Death Count": 94,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Method: "Strangling",
    "Death Count": 162,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Method: "JumpingIntoTheWater",
    "Death Count": 10,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Method: "UsingFirearms",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Method: "UsingHandBombsOrAnyOtherExplosives",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Method: "UsingSharpWeapons",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Method: "SettingFireToOneself",
    "Death Count": 16,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Method: "JumpingOnToTheMovingTrainsOrVehicles",
    "Death Count": 8,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Method: "DrinkingTheAcides",
    "Death Count": 5,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Method: "DrinkingTheFuel",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Method: "DrinkingTheWesternDrugs",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Method: "EatingTheNaturalPoisons",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Method: "JumpingFromTheHighPoints",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    Method: "Other",
    "Death Count": 7,
    Year: 2014,
  },
  // {
  //   "Age Group": "0-8",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "Strangling",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "UsingFirearms",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "Other",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 9,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "Strangling",
  //   "Death Count": 60,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 4,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "UsingFirearms",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 2,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 3,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "Other",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 35,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "Strangling",
  //   "Death Count": 124,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 10,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "UsingFirearms",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 9,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 11,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 3,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 12,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "Other",
  //   "Death Count": 2,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 50,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "Strangling",
  //   "Death Count": 195,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 5,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "UsingFirearms",
  //   "Death Count": 3,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 18,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 12,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 2,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 7,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "Other",
  //   "Death Count": 5,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 46,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "Strangling",
  //   "Death Count": 178,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 10,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "UsingFirearms",
  //   "Death Count": 3,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 23,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 13,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 3,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 8,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "Other",
  //   "Death Count": 4,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 56,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "Strangling",
  //   "Death Count": 128,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 6,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "UsingFirearms",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 10,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 19,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 2,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 4,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 11,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "Other",
  //   "Death Count": 3,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 67,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "Strangling",
  //   "Death Count": 151,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 6,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "UsingFirearms",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 11,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 13,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 5,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 9,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "Other",
  //   "Death Count": 2,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 78,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "Strangling",
  //   "Death Count": 150,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 3,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "UsingFirearms",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 3,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 10,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 9,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 3,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 8,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "Other",
  //   "Death Count": 3,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 82,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "Strangling",
  //   "Death Count": 114,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 6,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "UsingFirearms",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 2,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 13,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 18,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 3,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 6,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "Other",
  //   "Death Count": 4,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 114,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "Strangling",
  //   "Death Count": 153,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 11,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "UsingFirearms",
  //   "Death Count": 3,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 4,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 10,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 2,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 4,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 5,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "Other",
  //   "Death Count": 5,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 109,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "Strangling",
  //   "Death Count": 134,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 8,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "UsingFirearms",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 9,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 12,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 2,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 5,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 7,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "Other",
  //   "Death Count": 2,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 67,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "Strangling",
  //   "Death Count": 121,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 6,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "UsingFirearms",
  //   "Death Count": 2,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 2,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 9,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 2,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 4,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 5,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "Other",
  //   "Death Count": 4,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 50,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "Strangling",
  //   "Death Count": 80,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 8,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "UsingFirearms",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 3,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 8,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 2,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 4,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "Other",
  //   "Death Count": 5,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 103,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "Strangling",
  //   "Death Count": 158,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 13,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "UsingFirearms",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 13,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 12,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 2,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 3,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 1,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "Other",
  //   "Death Count": 5,
  //   Year: 2013,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "Strangling",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "UsingFirearms",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "Other",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 7,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "Strangling",
  //   "Death Count": 62,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 5,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "UsingFirearms",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 3,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 2,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 5,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "Other",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 39,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "Strangling",
  //   "Death Count": 142,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 11,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "UsingFirearms",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 8,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 9,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 2,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 4,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 10,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "Other",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 49,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "Strangling",
  //   "Death Count": 186,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 6,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "UsingFirearms",
  //   "Death Count": 2,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 18,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 13,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 4,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 5,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "Other",
  //   "Death Count": 2,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 57,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "Strangling",
  //   "Death Count": 163,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 12,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "UsingFirearms",
  //   "Death Count": 2,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 19,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 12,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 2,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 2,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 6,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "Other",
  //   "Death Count": 3,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 53,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "Strangling",
  //   "Death Count": 120,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 9,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "UsingFirearms",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 12,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 16,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 3,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 10,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "Other",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 73,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "Strangling",
  //   "Death Count": 120,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 3,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "UsingFirearms",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 9,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 11,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 2,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 4,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 7,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "Other",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 72,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "Strangling",
  //   "Death Count": 148,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 3,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "UsingFirearms",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 7,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 11,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 2,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 2,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 10,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "Other",
  //   "Death Count": 6,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 80,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "Strangling",
  //   "Death Count": 110,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 3,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "UsingFirearms",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 10,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 15,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 2,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 2,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 7,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "Other",
  //   "Death Count": 5,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 120,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "Strangling",
  //   "Death Count": 150,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 10,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "UsingFirearms",
  //   "Death Count": 2,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 6,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 9,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 3,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 5,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "Other",
  //   "Death Count": 2,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 105,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "Strangling",
  //   "Death Count": 132,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 11,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "UsingFirearms",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 10,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 12,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 3,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 3,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 5,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "Other",
  //   "Death Count": 2,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 80,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "Strangling",
  //   "Death Count": 113,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 3,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "UsingFirearms",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 3,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 10,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 3,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 7,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "Other",
  //   "Death Count": 2,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 49,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "Strangling",
  //   "Death Count": 78,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 6,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "UsingFirearms",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 5,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 6,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 2,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 5,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "Other",
  //   "Death Count": 3,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 99,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "Strangling",
  //   "Death Count": 138,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 11,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "UsingFirearms",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 12,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 10,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 3,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 1,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 2,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 5,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "Other",
  //   "Death Count": 3,
  //   Year: 2012,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "Strangling",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "UsingFirearms",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "0-8",
  //   Method: "Other",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 8,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "Strangling",
  //   "Death Count": 57,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 5,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "UsingFirearms",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 5,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 2,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "8-16",
  //   Method: "Other",
  //   "Death Count": 2,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 43,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "Strangling",
  //   "Death Count": 140,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 8,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "UsingFirearms",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 10,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 8,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 2,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 11,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "17-20",
  //   Method: "Other",
  //   "Death Count": 2,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 42,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "Strangling",
  //   "Death Count": 180,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 5,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "UsingFirearms",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 19,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 11,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 3,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 9,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "21-25",
  //   Method: "Other",
  //   "Death Count": 3,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 50,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "Strangling",
  //   "Death Count": 169,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 10,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "UsingFirearms",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 20,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 11,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 3,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 5,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "26-30",
  //   Method: "Other",
  //   "Death Count": 2,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 50,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "Strangling",
  //   "Death Count": 120,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 8,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "UsingFirearms",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 18,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 17,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 2,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 2,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 10,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "31-35",
  //   Method: "Other",
  //   "Death Count": 2,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 80,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "Strangling",
  //   "Death Count": 139,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 5,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "UsingFirearms",
  //   "Death Count": 2,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 9,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 12,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 2,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 10,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "36-40",
  //   Method: "Other",
  //   "Death Count": 2,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 80,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "Strangling",
  //   "Death Count": 154,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 3,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "UsingFirearms",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 2,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 12,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 10,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 3,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 5,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "41-45",
  //   Method: "Other",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 79,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "Strangling",
  //   "Death Count": 103,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 8,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "UsingFirearms",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 9,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 17,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 3,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 5,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "46-50",
  //   Method: "Other",
  //   "Death Count": 2,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 122,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "Strangling",
  //   "Death Count": 150,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 10,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "UsingFirearms",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 3,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 5,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 12,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 2,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 3,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 8,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "51-55",
  //   Method: "Other",
  //   "Death Count": 3,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 111,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "Strangling",
  //   "Death Count": 135,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 10,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "UsingFirearms",
  //   "Death Count": 12,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 11,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 10,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 2,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 3,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 9,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "56-60",
  //   Method: "Other",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 85,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "Strangling",
  //   "Death Count": 129,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 10,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "UsingFirearms",
  //   "Death Count": 2,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 4,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 7,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 2,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 5,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "61-65",
  //   Method: "Other",
  //   "Death Count": 3,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 64,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "Strangling",
  //   "Death Count": 97,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 10,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "UsingFirearms",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 2,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 9,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 3,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "66-70",
  //   Method: "Other",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "DrinkingInsecticidesAndPecticides",
  //   "Death Count": 101,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "Strangling",
  //   "Death Count": 160,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "JumpingIntoTheWater",
  //   "Death Count": 11,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "UsingFirearms",
  //   "Death Count": 3,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "UsingHandBombsOrAnyOtherExplosives",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "UsingSharpWeapons",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "SettingFireToOneself",
  //   "Death Count": 9,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "JumpingOnToTheMovingTrainsOrVehicles",
  //   "Death Count": 12,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "DrinkingTheAcides",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "DrinkingTheFuel",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "DrinkingTheWesternDrugs",
  //   "Death Count": 1,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "EatingTheNaturalPoisons",
  //   "Death Count": 3,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "JumpingFromTheHighPoints",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "DrinkingOrInjectingOneselfTheNarcoticDrugs",
  //   "Death Count": 0,
  //   Year: 2011,
  // },
  // {
  //   "Age Group": "71-100",
  //   Method: "Other",
  //   "Death Count": 8,
  //   Year: 2011,
  // },
];
