export const occupationData = [
  {
    "Age Group": "0-8",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "SalesWorkers",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "SalesWorkers",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "Students",
    "Death Count": 60,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 10,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "SalesWorkers",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 8,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "ArmedServices",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "Students",
    "Death Count": 58,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 83,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 9,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "SalesWorkers",
    "Death Count": 12,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 35,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 22,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "ArmedServices",
    "Death Count": 8,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "Students",
    "Death Count": 4,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 133,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 9,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "SalesWorkers",
    "Death Count": 14,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 7,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 28,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 13,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "ArmedServices",
    "Death Count": 6,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "Police",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "Students",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 90,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 10,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 4,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "SalesWorkers",
    "Death Count": 11,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 12,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 35,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 18,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "ArmedServices",
    "Death Count": 6,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 85,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "SalesWorkers",
    "Death Count": 10,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 8,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 36,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 16,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "ArmedServices",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 65,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 7,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "SalesWorkers",
    "Death Count": 12,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 6,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 49,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 17,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "ArmedServices",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "Pensioners",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 74,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 7,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "SalesWorkers",
    "Death Count": 8,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 52,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 23,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "ArmedServices",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "Police",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "Pensioners",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "Students",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 70,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 6,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "SalesWorkers",
    "Death Count": 10,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 58,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 13,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "ArmedServices",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "Police",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "Pensioners",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 92,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 4,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "SalesWorkers",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 78,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 25,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "ArmedServices",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "Police",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "Pensioners",
    "Death Count": 10,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 83,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "SalesWorkers",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 10,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 4,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "Pensioners",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 90,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "SalesWorkers",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 35,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 5,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "Police",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 3,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "Pensioners",
    "Death Count": 7,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "UnemployedPersons",
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "SalesWorkers",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 55,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 11,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "Police",
    "Death Count": 1,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 2,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "Pensioners",
    "Death Count": 12,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2018,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 180,
    Year: 2018,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "SalesWorkers",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "SalesWorkers",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "Students",
    "Death Count": 64,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 20,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "SalesWorkers",
    "Death Count": 7,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 6,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 8,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "ArmedServices",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "Students",
    "Death Count": 64,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 80,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 4,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "SalesWorkers",
    "Death Count": 10,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 12,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 30,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 14,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "ArmedServices",
    "Death Count": 5,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "Police",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "Students",
    "Death Count": 7,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 130,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 10,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 4,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "SalesWorkers",
    "Death Count": 8,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 6,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 31,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 19,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "ArmedServices",
    "Death Count": 10,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "Pensioners",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "Students",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 118,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 13,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 5,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "SalesWorkers",
    "Death Count": 17,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 10,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 38,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 15,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "ArmedServices",
    "Death Count": 6,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "Students",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 92,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 7,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 5,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "SalesWorkers",
    "Death Count": 16,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 7,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 38,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 15,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "ArmedServices",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 4,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 79,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 9,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 4,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "SalesWorkers",
    "Death Count": 14,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 5,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 52,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 12,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "ArmedServices",
    "Death Count": 4,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "Police",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "Pensioners",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 77,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 6,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "SalesWorkers",
    "Death Count": 11,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 6,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 58,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 24,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "ArmedServices",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "Pensioners",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "Students",
    "Death Count": 5,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 66,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 7,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "SalesWorkers",
    "Death Count": 9,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 4,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 56,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 11,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "ArmedServices",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "Pensioners",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 95,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "SalesWorkers",
    "Death Count": 6,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 80,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 18,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "Police",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "Pensioners",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 86,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 4,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "SalesWorkers",
    "Death Count": 8,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "Pensioners",
    "Death Count": 8,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 97,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "SalesWorkers",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 27,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "Police",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "Pensioners",
    "Death Count": 9,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 94,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 4,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "SalesWorkers",
    "Death Count": 4,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 1,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 43,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 3,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 2,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "Pensioners",
    "Death Count": 17,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2017,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 182,
    Year: 2017,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "SalesWorkers",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "SalesWorkers",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "Students",
    "Death Count": 63,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 11,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "SalesWorkers",
    "Death Count": 6,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 7,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 7,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 10,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "ArmedServices",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "Students",
    "Death Count": 54,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 101,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 7,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "SalesWorkers",
    "Death Count": 11,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 6,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 35,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 23,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "ArmedServices",
    "Death Count": 11,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "Students",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 105,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 5,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 4,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "SalesWorkers",
    "Death Count": 14,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 4,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 25,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 11,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "ArmedServices",
    "Death Count": 7,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 98,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 5,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 4,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "SalesWorkers",
    "Death Count": 10,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 4,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 36,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 17,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "ArmedServices",
    "Death Count": 5,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "Police",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 93,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 5,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "SalesWorkers",
    "Death Count": 10,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 9,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 37,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 26,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "ArmedServices",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 64,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 5,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 4,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "SalesWorkers",
    "Death Count": 6,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 4,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 34,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 16,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "ArmedServices",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "Police",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "Pensioners",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 71,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 6,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "SalesWorkers",
    "Death Count": 6,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 4,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 48,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 24,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "ArmedServices",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "Police",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "Pensioners",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 73,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 7,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 4,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "SalesWorkers",
    "Death Count": 8,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 4,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 44,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 23,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "ArmedServices",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "Police",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "Pensioners",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 91,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "SalesWorkers",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 52,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 21,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "Pensioners",
    "Death Count": 5,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 85,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "SalesWorkers",
    "Death Count": 5,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 31,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 9,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "Pensioners",
    "Death Count": 7,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 73,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "SalesWorkers",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 30,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 10,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "Police",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "Pensioners",
    "Death Count": 10,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 86,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "SalesWorkers",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 3,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 37,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 8,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "Police",
    "Death Count": 2,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "Pensioners",
    "Death Count": 10,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2016,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 166,
    Year: 2016,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "SalesWorkers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "SalesWorkers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "Students",
    "Death Count": 55,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 7,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "SalesWorkers",
    "Death Count": 4,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 5,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 4,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 9,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "ArmedServices",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "Students",
    "Death Count": 77,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 105,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 9,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 6,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "SalesWorkers",
    "Death Count": 11,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 3,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 27,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 19,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "ArmedServices",
    "Death Count": 14,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "Students",
    "Death Count": 6,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 118,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 8,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 3,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "SalesWorkers",
    "Death Count": 9,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 8,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 34,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 28,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "ArmedServices",
    "Death Count": 5,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "Police",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 4,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "Students",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 101,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 6,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "SalesWorkers",
    "Death Count": 10,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 13,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 39,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 15,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "ArmedServices",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 95,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 7,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "SalesWorkers",
    "Death Count": 11,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 4,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 47,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 27,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "ArmedServices",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 3,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 85,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 3,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "SalesWorkers",
    "Death Count": 9,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 46,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 27,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "ArmedServices",
    "Death Count": 4,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "Pensioners",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 70,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 3,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "SalesWorkers",
    "Death Count": 10,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 3,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 63,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 21,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "ArmedServices",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "Police",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "Pensioners",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 83,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 4,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "SalesWorkers",
    "Death Count": 7,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 4,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 63,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 19,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "Pensioners",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "Politicians",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 94,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 3,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 3,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "SalesWorkers",
    "Death Count": 5,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 3,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 70,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 11,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "Pensioners",
    "Death Count": 11,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 85,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "SalesWorkers",
    "Death Count": 6,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 2,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 52,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 9,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "Pensioners",
    "Death Count": 8,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 97,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "SalesWorkers",
    "Death Count": 3,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 21,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 7,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "Pensioners",
    "Death Count": 8,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 65,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "SalesWorkers",
    "Death Count": 3,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 31,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 12,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "Police",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "Pensioners",
    "Death Count": 15,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2015,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 174,
    Year: 2015,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "SalesWorkers",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "SalesWorkers",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "Students",
    "Death Count": 28,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "8-16",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 12,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "SalesWorkers",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 5,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 9,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "ArmedServices",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "Students",
    "Death Count": 38,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "17-20",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 90,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 11,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 4,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "SalesWorkers",
    "Death Count": 8,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 8,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 20,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 11,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "ArmedServices",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "Students",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "21-25",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 93,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 7,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 5,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "SalesWorkers",
    "Death Count": 12,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 6,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 29,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 16,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "ArmedServices",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "26-30",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 89,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 11,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 4,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "SalesWorkers",
    "Death Count": 8,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 18,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 21,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 5,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "ArmedServices",
    "Death Count": 7,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "Police",
    "Death Count": 5,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "31-35",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 90,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 4,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "SalesWorkers",
    "Death Count": 10,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 11,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 21,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 19,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "Pensioners",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "36-40",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 80,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "SalesWorkers",
    "Death Count": 5,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 20,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 18,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "ArmedServices",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "Police",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "Pensioners",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "41-45",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 59,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 4,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "SalesWorkers",
    "Death Count": 7,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 25,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 25,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "ArmedServices",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "Police",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "Pensioners",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "46-50",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 75,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 4,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "SalesWorkers",
    "Death Count": 8,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 29,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 10,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "ArmedServices",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "Police",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "Pensioners",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "51-55",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 83,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "SalesWorkers",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 31,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 20,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "ArmedServices",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "Police",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "Pensioners",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "56-60",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 81,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "SalesWorkers",
    "Death Count": 5,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 24,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 7,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "ArmedServices",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "Police",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "Pensioners",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "61-65",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 77,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 5,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "SalesWorkers",
    "Death Count": 6,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 17,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 3,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "Police",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "Pensioners",
    "Death Count": 4,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "66-70",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 70,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 1,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "SalesWorkers",
    "Death Count": 4,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "ServiceWorkers",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    "Occupation Level":
      "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
    "Death Count": 43,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    "Occupation Level":
      "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
    "Death Count": 4,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "ArmedServices",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "Police",
    "Death Count": 2,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "SecurityPersonnel",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "Pensioners",
    "Death Count": 6,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "Students",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "Politicians",
    "Death Count": 0,
    Year: 2014,
  },
  {
    "Age Group": "71-100",
    "Occupation Level": "UnemployedPersons",
    "Death Count": 162,
    Year: 2014,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2013,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
    "Death Count": 0,
    Year: 2013,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "ClericalAndRelatedWorkers",
    "Death Count": 0,
    Year: 2013,
  },
  {
    "Age Group": "0-8",
    "Occupation Level": "SalesWorkers",
    "Death Count": 0,
    Year: 2013,
  },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "Students",
  //     "Death Count": 45,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 5,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 3,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 12,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 20,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 4,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 8,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "Students",
  //     "Death Count": 50,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 89,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 10,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 7,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 9,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 10,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 30,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 14,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 5,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "Students",
  //     "Death Count": 4,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 90,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 6,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 3,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 20,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 10,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 18,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 12,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "Students",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 108,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 5,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 3,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 12,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 9,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 17,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 7,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 3,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "Police",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "Students",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 87,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 3,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 14,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 32,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 14,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 78,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 11,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 3,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 24,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 13,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 8,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 68,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 5,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 28,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 18,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 83,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 5,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 3,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 5,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 28,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 13,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 87,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 3,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 49,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 16,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 79,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 4,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 18,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 6,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 3,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 80,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 7,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 23,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 8,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 7,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 3,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 72,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 38,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 16,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 1,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 2,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 172,
  //     Year: 2013,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "Students",
  //     "Death Count": 36,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 10,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 6,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 8,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 6,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 7,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 3,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "Students",
  //     "Death Count": 52,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 81,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 8,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 6,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 12,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 22,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 27,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 12,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 3,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "Students",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 92,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 5,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 3,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 6,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 10,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 5,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 32,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 17,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 8,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 92,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 3,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 7,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 5,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 24,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 11,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 83,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 4,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 3,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 17,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 28,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 22,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 82,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 6,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 4,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 7,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 33,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 21,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 60,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 3,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 4,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 8,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 34,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 18,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "Police",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 3,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 71,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 3,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 9,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 30,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 12,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 84,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 4,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 53,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 13,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "Police",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 3,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 80,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 5,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 4,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 20,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 5,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 3,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 78,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 3,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 15,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 6,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 4,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 68,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 3,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 2,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 25,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 9,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 4,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 173,
  //     Year: 2012,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "0-8",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "Students",
  //     "Death Count": 24,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "8-16",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 8,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 7,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 5,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 10,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 6,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 3,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 2,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "Students",
  //     "Death Count": 63,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "17-20",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 78,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 4,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 3,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 10,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 16,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 32,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 13,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 2,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 3,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "Students",
  //     "Death Count": 3,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "21-25",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 100,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 4,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 3,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 7,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 16,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 5,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 29,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 18,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 7,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 2,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "26-30",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 89,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 10,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 5,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 3,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 15,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 7,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 30,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 14,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "Police",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "31-35",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 91,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 5,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 3,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 12,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 2,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 40,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 12,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "36-40",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 87,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 5,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 9,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 11,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 3,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 30,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 17,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 5,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "Police",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "41-45",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 65,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 5,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 6,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 38,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 11,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "Police",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 2,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "46-50",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 74,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 5,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 12,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 4,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 40,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 11,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 2,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "51-55",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 89,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 5,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 2,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 52,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 20,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "56-60",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 82,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 3,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 3,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 22,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 5,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 3,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "61-65",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 80,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 4,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 3,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 17,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 5,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 2,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "66-70",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 70,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "ProfessionalTechnicalAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "AdministrativeExecutiveManagerialAndRelatedWorkers",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "ClericalAndRelatedWorkers",
  //     "Death Count": 2,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "SalesWorkers",
  //     "Death Count": 4,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "ServiceWorkers",
  //     "Death Count": 1,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level":
  //       "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers",
  //     "Death Count": 28,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level":
  //       "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers",
  //     "Death Count": 13,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "ArmedServices",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "Police",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "SecurityPersonnel",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "Pensioners",
  //     "Death Count": 2,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "Students",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "Politicians",
  //     "Death Count": 0,
  //     Year: 2011,
  //   },
  //   {
  //     "Age Group": "71-100",
  //     "Occupation Level": "UnemployedPersons",
  //     "Death Count": 169,
  //     Year: 2011,
  //   },
];
