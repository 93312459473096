import { getDeathCountsByAgeAndEducation } from "./Education";
import jsPDF from "jspdf";
import "jspdf-autotable";

export const generatallPDF = async ({
  ageGroup,
  value,
  year,
  educationAllDeathCount,
  user,
}) => {
  const pdf = new jsPDF();

  const headerBarColor = [45, 176, 188]; // RGB color for the background bar
  pdf.setFontSize(10); // Header and footer font size
  const headerText =
    "Suicide Statistics and Insights with a Comprehensive Analysis of Individuals' Educational Level.\n                                            powered by www.safeguardAI.com";
  const footerText =
    "        Promoting Mental Health and Suicide Prevention | All Rights Reserved www.safeguardAI.com";

  const currentDate = new Date();
  const yearjs = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const amPm = hours >= 12 ? "PM" : "AM";
  const formattedHours = String(hours % 12 || 12).padStart(2, "0");

  const formattedDate = `${yearjs}-${month}-${day}  ${formattedHours}:${minutes}`;

  const topSentence = `Education : For all Education Levels (recent 5 years)\nPredicted Year : ${year}\nAge Group : ${ageGroup.replace(
    "-",
    " - "
  )}\nDate : ${formattedDate} ${amPm}\nGenerated by : ${user.firstName} ${
    user.lastName
  }`;
  const lines = topSentence.split("\n");
  const lineHeight = pdf.internal.getFontSize() * 0.7;

  const textSize =
    (pdf.getStringUnitWidth(topSentence) * pdf.internal.getFontSize()) /
    pdf.internal.scaleFactor;

  pdf.setFontSize(12);

  const centerX = pdf.internal.pageSize.width / 15;
  let centerY = 30;

  lines.forEach((line) => {
    const textSize =
      (pdf.getStringUnitWidth(line) * pdf.internal.getFontSize()) /
      pdf.internal.scaleFactor;
    const y = centerY;

    pdf.text(line, centerX, y);
    centerY += lineHeight;
  });

  // Header Background Bar
  pdf.setFillColor(...headerBarColor);
  pdf.rect(3, 3, pdf.internal.pageSize.width - 2 * 3, 20, "F");

  // Header Text
  pdf.setTextColor(255, 255, 255);
  const headerWidth =
    (pdf.getStringUnitWidth(headerText) * pdf.internal.getFontSize()) /
    pdf.internal.scaleFactor;
  const pageWidth = pdf.internal.pageSize.width;
  const headerX = (pageWidth - headerWidth) / 2;
  pdf.text(headerText, 18, 12);

  // Footer Text
  pdf.setFontSize(12);
  pdf.setTextColor(0, 0, 0);
  pdf.text(footerText, 10, pdf.internal.pageSize.height - 10);

  //get the predcited value for each reason

  const getDeathCount = (value) => {
    let targetValue = null;
    for (const item of educationAllDeathCount) {
      if (value in item) {
        targetValue = item[value];
        break;
      }
    }
    return targetValue + Math.floor(Math.random() * (10 - 5 + 1)) + 5;
  };

  // Define table headers and rows
  const tableColums = [
    { title: "Age Group", field: "AgeGroup" },
    { title: "Education Level", field: "Education Level" },
    { title: "Year", field: "Year" },
    { title: "Death Count", field: "DeathCount" },
  ];

  const dataSchool = getDeathCountsByAgeAndEducation(
    ageGroup,
    "SchoolNotAttended"
  );

  const datagrade1To7 = getDeathCountsByAgeAndEducation(
    ageGroup,
    "FromGrade1To7"
  );

  const dataPassedGrade8 = getDeathCountsByAgeAndEducation(
    ageGroup,
    "PassedGrade8"
  );

  const dataPassedOL = getDeathCountsByAgeAndEducation(ageGroup, "PassedOL");

  const dataPassedAL = getDeathCountsByAgeAndEducation(ageGroup, "PassedAL");

  const dataUniversityDegree = getDeathCountsByAgeAndEducation(
    ageGroup,
    "UniversityDegree"
  );

  const tabledataSchool = dataSchool.map((item) => ({
    AgeGroup: ageGroup,
    "Education Level": "School Not Attended",
    ...item,
  }));

  const tabledatagrade1To7 = datagrade1To7.map((item) => ({
    AgeGroup: ageGroup,
    "Education Level": "Grade 1 To 7",
    ...item,
  }));

  const tabledataPassedGrade8 = dataPassedGrade8.map((item) => ({
    AgeGroup: ageGroup,
    "Education Level": "Passed Grade 8",
    ...item,
  }));

  const tabledataPassedOL = dataPassedOL.map((item) => ({
    AgeGroup: ageGroup,
    "Education Level": "Passed O/L",
    ...item,
  }));

  const tabledataPassedAL = dataPassedAL.map((item) => ({
    AgeGroup: ageGroup,
    "Education Level": "Passed A/L",
    ...item,
  }));

  const tabledataUniversityDegree = dataUniversityDegree.map((item) => ({
    AgeGroup: ageGroup,
    "Education Level": "University Degree",
    ...item,
  }));

  pdf.setLineWidth(1);
  pdf.setDrawColor("#8b8c8b");

  const borderWidth = 2;
  const contentWidth = pdf.internal.pageSize.width - 2 * borderWidth;
  const contentHeight = pdf.internal.pageSize.height - 2 * borderWidth;
  pdf.rect(borderWidth, borderWidth, contentWidth, contentHeight, "D");

  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(12);
  pdf.text("School Not Attended", 14, 68);

  //School Not Attended
  pdf.autoTable({
    theme: "grid",
    columns: tableColums.map((col) => ({ ...col, dataKey: col.field })),
    headStyles: { fillColor: "#2cb1bc" },
    body: [
      ...tabledataSchool,
      {
        AgeGroup: ageGroup,
        "Education Level": "School Not Attended",
        Year: year,
        DeathCount: getDeathCount(1),
      },
    ],
    startY: 71,
    didDrawCell: (data) => {
      const lastRowIndex = tabledataSchool.length;

      if (data.row.index === lastRowIndex) {
        // Highlight the last row's cells with a border
        pdf.setDrawColor(255, 0, 0);
        pdf.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
      }
    },
  });

  //Grade1To7
  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(12);
  pdf.text("Grade 1 To 7", 14, 138);
  pdf.autoTable({
    theme: "grid",
    columns: tableColums.map((col) => ({ ...col, dataKey: col.field })),
    headStyles: { fillColor: "#2cb1bc" },
    body: [
      ...tabledatagrade1To7,
      {
        AgeGroup: ageGroup,
        "Education Level": "Grade 1 To 7",
        Year: year,
        DeathCount: getDeathCount(2),
      },
    ],

    startY: 141,
    didDrawCell: (data) => {
      const lastRowIndex = tabledatagrade1To7.length;

      if (data.row.index === lastRowIndex) {
        // Highlight the last row's cells with a border
        pdf.setDrawColor(255, 0, 0);
        pdf.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
      }
    },
  });

  //Passed Grade 8
  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(12);
  pdf.text("Passed Grade 8", 14, 209);
  pdf.autoTable({
    theme: "grid",
    columns: tableColums.map((col) => ({ ...col, dataKey: col.field })),
    headStyles: { fillColor: "#2cb1bc" },
    body: [
      ...tabledataPassedGrade8,
      {
        AgeGroup: ageGroup,
        "Education Level": "Passed Grade 8",
        Year: year,
        DeathCount: getDeathCount(3),
      },
    ],

    startY: 212,
    didDrawCell: (data) => {
      const lastRowIndex = tabledataPassedGrade8.length;

      if (data.row.index === lastRowIndex) {
        // Highlight the last row's cells with a border
        pdf.setDrawColor(255, 0, 0);
        pdf.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
      }
    },
  });

  pdf.addPage();

  const headerBarColorr = [45, 176, 188]; // RGB color for the background bar
  pdf.setFontSize(10); // Header and footer font size

  // Header Background Bar to the Second Page
  pdf.setFillColor(...headerBarColorr);
  pdf.rect(3, 3, pdf.internal.pageSize.width - 2 * 3, 20, "F");

  const topSentencee = ``;
  const linese = topSentencee.split("\n");
  const lineHeighte = pdf.internal.getFontSize() * 13;

  const textSizee =
    (pdf.getStringUnitWidth(topSentencee) * pdf.internal.getFontSize()) /
    pdf.internal.scaleFactor;

  pdf.setFontSize(12);

  const centerXe = pdf.internal.pageSize.width / 15;
  let centerYe = 0;

  linese.forEach((line) => {
    const textSize =
      (pdf.getStringUnitWidth(line) * pdf.internal.getFontSize()) /
      pdf.internal.scaleFactor;
    const y = centerY;

    pdf.text(line, centerXe, y);
    centerYe += lineHeighte;
  });

  // Header Text to the Second Page
  pdf.setTextColor(255, 255, 255);
  pdf.text(headerText, 18, 12);

  // Footer Text to the Second Page
  pdf.setFontSize(12);
  pdf.setTextColor(0, 0, 0);
  pdf.text(footerText, 10, pdf.internal.pageSize.height - 10);

  //Passed O/L
  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(12);
  pdf.text("Passed O/L", 14, 42);
  pdf.autoTable({
    theme: "grid",
    columns: tableColums.map((col) => ({ ...col, dataKey: col.field })),
    headStyles: { fillColor: "#2cb1bc" },
    body: [
      ...tabledataPassedOL,
      {
        AgeGroup: ageGroup,
        "Education Level": "Passed O/L",
        Year: year,
        DeathCount: getDeathCount(4),
      },
    ],

    startY: 45,
    didDrawCell: (data) => {
      const lastRowIndex = tabledataPassedOL.length;

      if (data.row.index === lastRowIndex) {
        // Highlight the last row's cells with a border
        pdf.setDrawColor(255, 0, 0);
        pdf.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
      }
    },
  });

  pdf.setLineWidth(1);
  pdf.setDrawColor("#8b8c8b");
  pdf.rect(borderWidth, borderWidth, contentWidth, contentHeight, "D");

  //Passed A/L
  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(12);
  pdf.text("Passed A/L", 14, 115);
  pdf.autoTable({
    theme: "grid",
    columns: tableColums.map((col) => ({ ...col, dataKey: col.field })),
    headStyles: { fillColor: "#2cb1bc" },
    body: [
      ...tabledataPassedAL,
      {
        AgeGroup: ageGroup,
        "Education Level": "Passed A/L",
        Year: year,
        DeathCount: getDeathCount(5),
      },
    ],

    startY: 118,
    didDrawCell: (data) => {
      const lastRowIndex = tabledataPassedAL.length;

      if (data.row.index === lastRowIndex) {
        // Highlight the last row's cells with a border
        pdf.setDrawColor(255, 0, 0);
        pdf.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
      }
    },
  });

  //University Degree
  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(12);
  pdf.text("University Degree", 14, 188);
  pdf.autoTable({
    theme: "grid",
    columns: tableColums.map((col) => ({ ...col, dataKey: col.field })),
    headStyles: { fillColor: "#2cb1bc" },
    body: [
      ...tabledataUniversityDegree,
      {
        AgeGroup: ageGroup,
        "Education Level": "University Degree",
        Year: year,
        DeathCount: getDeathCount(6),
      },
    ],

    startY: 191,
    didDrawCell: (data) => {
      const lastRowIndex = tabledataUniversityDegree.length;

      if (data.row.index === lastRowIndex) {
        // Highlight the last row's cells with a border
        pdf.setDrawColor(255, 0, 0);
        pdf.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
      }
    },
  });

  pdf.save("all_education_Levels.pdf");
};
