import { getDeathCountsByAgeAndOccupation } from "./Occupation";
import jsPDF from "jspdf";
import "jspdf-autotable";

export const generatallPDF = async ({
  ageGroup,
  value,
  year,
  occupationAllDeathCount,
  user,
}) => {
  const pdf = new jsPDF();

  // Header and Footer to Each Page
  const headerBarColor = [45, 176, 188]; // RGB color for the background bar
  pdf.setFontSize(10); // Header and footer font size
  const headerText =
    "Suicide Statistics and Insights with a Comprehensive Analysis of Individuals' Occupation Level.\n                                            powered by www.safeguardAI.com";
  const footerText =
    "        Promoting Mental Health and Suicide Prevention | All Rights Reserved www.safeguardAI.com";

  const currentDate = new Date();
  const yearjs = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const amPm = hours >= 12 ? "PM" : "AM";
  const formattedHours = String(hours % 12 || 12).padStart(2, "0");

  const formattedDate = `${yearjs}-${month}-${day}  ${formattedHours}:${minutes}`;

  const topSentence = `Occupation Level : For all Occupation Levels (recent 5 years)\nPredicted Year : ${year}\nAge Group : ${ageGroup.replace(
    "-",
    " - "
  )}\nDate : ${formattedDate} ${amPm}\nGenerated by : ${user.firstName} ${
    user.lastName
  }`;
  const lines = topSentence.split("\n");
  const lineHeight = pdf.internal.getFontSize() * 0.7;

  const textSize =
    (pdf.getStringUnitWidth(topSentence) * pdf.internal.getFontSize()) /
    pdf.internal.scaleFactor;

  pdf.setFontSize(12);

  const centerX = pdf.internal.pageSize.width / 15;
  let centerY = 30;

  lines.forEach((line) => {
    const textSize =
      (pdf.getStringUnitWidth(line) * pdf.internal.getFontSize()) /
      pdf.internal.scaleFactor;
    const y = centerY;

    pdf.text(line, centerX, y);
    centerY += lineHeight;
  });

  pdf.setFillColor(...headerBarColor);
  pdf.rect(3, 3, pdf.internal.pageSize.width - 2 * 3, 20, "F");

  pdf.setTextColor(255, 255, 255);
  const headerWidth =
    (pdf.getStringUnitWidth(headerText) * pdf.internal.getFontSize()) /
    pdf.internal.scaleFactor;
  const pageWidth = pdf.internal.pageSize.width;
  const headerX = (pageWidth - headerWidth) / 2;
  pdf.text(headerText, 20, 12);

  pdf.setFontSize(12);
  pdf.setTextColor(0, 0, 0);
  pdf.text(footerText, 10, pdf.internal.pageSize.height - 10);

  //get the predcited value for each reason

  const getDeathCount = (value) => {
    let targetValue = null;
    for (const item of occupationAllDeathCount) {
      if (value in item) {
        targetValue = item[value];
        break;
      }
    }
    return targetValue;
  };

  // Define table headers and rows
  const tableColums = [
    { title: "Age Group", field: "AgeGroup" },
    { title: "Occupation Level", field: "Occupation Level" },
    { title: "Year", field: "Year" },
    { title: "Death Count", field: "DeathCount" },
  ];

  const dataProfessional = getDeathCountsByAgeAndOccupation(
    ageGroup,
    "ProfessionalTechnicalAndRelatedWorkers"
  );

  const dataAgricultural = getDeathCountsByAgeAndOccupation(
    ageGroup,
    "AgriculturalAnimalHusbandryFishermanAndrelatedForestryWorkers"
  );

  const dataProduction = getDeathCountsByAgeAndOccupation(
    ageGroup,
    "ProductionProcessWorkersCraftsmanAndRelatedWorkersTransportEquipmentOperatorsAndLabourers"
  );

  const dataSalesWorkers = getDeathCountsByAgeAndOccupation(
    ageGroup,
    "SalesWorkers"
  );

  const dataServiceWorkers = getDeathCountsByAgeAndOccupation(
    ageGroup,
    "ServiceWorkers"
  );

  const dataUnemployedPersons = getDeathCountsByAgeAndOccupation(
    ageGroup,
    "UnemployedPersons"
  );

  const tabledataProfessional = dataProfessional.map((item) => ({
    AgeGroup: ageGroup,
    "Occupation Level": "Professional Technical And Related Workers",
    ...item,
  }));

  const tabledataAgricultural = dataAgricultural.map((item) => ({
    AgeGroup: ageGroup,
    "Occupation Level":
      "Agricultural, Animal Husbandry, Fisherman & Forestry Workers",
    ...item,
  }));

  const tabledataProduction = dataProduction.map((item) => ({
    AgeGroup: ageGroup,
    "Occupation Level":
      "Process Workers, Craftsmen, Transport Operators & Labourers",
    ...item,
  }));

  const tabledataSalesWorkers = dataSalesWorkers.map((item) => ({
    AgeGroup: ageGroup,
    "Occupation Level": "Sales Workers",
    ...item,
  }));

  const tabledataServiceWorkers = dataServiceWorkers.map((item) => ({
    AgeGroup: ageGroup,
    "Occupation Level": "Service Workers",
    ...item,
  }));

  const tabledataUnemployedPersons = dataUnemployedPersons.map((item) => ({
    AgeGroup: ageGroup,
    "Occupation Level": "Unemployed Persons",
    ...item,
  }));

  pdf.setLineWidth(1);
  pdf.setDrawColor("#8b8c8b");

  const borderWidth = 2;
  const contentWidth = pdf.internal.pageSize.width - 2 * borderWidth;
  const contentHeight = pdf.internal.pageSize.height - 2 * borderWidth;
  pdf.rect(borderWidth, borderWidth, contentWidth, contentHeight, "D");

  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(12);
  pdf.text("Professional Technical And Related Workers", 14, 68);

  //Professional
  pdf.autoTable({
    theme: "grid",
    columns: tableColums.map((col) => ({ ...col, dataKey: col.field })),
    headStyles: { fillColor: "#2cb1bc" },
    body: [
      ...tabledataProfessional,
      {
        AgeGroup: ageGroup,
        "Occupation Level": "Professional Technical And Related Workers",
        Year: year,
        DeathCount: getDeathCount(1),
      },
    ],
    startY: 71,
    didDrawCell: (data) => {
      const lastRowIndex = tabledataProfessional.length;

      if (data.row.index === lastRowIndex) {
        // Highlight the last row's cells with a border
        pdf.setDrawColor(255, 0, 0);
        pdf.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
      }
    },
  });

  //Agricultural, Animal Husbandry, Fisherman & Forestry Workers
  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(12);
  pdf.text(
    "Agricultural, Animal Husbandry, Fisherman & Forestry Workers",
    14,
    138
  );
  pdf.autoTable({
    theme: "grid",
    columns: tableColums.map((col) => ({ ...col, dataKey: col.field })),
    headStyles: { fillColor: "#2cb1bc" },
    body: [
      ...tabledataAgricultural,
      {
        AgeGroup: ageGroup,
        "Occupation Level":
          "Agricultural, Animal Husbandry, Fisherman & Forestry Workers",
        Year: year,
        DeathCount: getDeathCount(6),
      },
    ],

    startY: 141,
    didDrawCell: (data) => {
      const lastRowIndex = tabledataAgricultural.length;

      if (data.row.index === lastRowIndex) {
        // Highlight the last row's cells with a border
        pdf.setDrawColor(255, 0, 0);
        pdf.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
      }
    },
  });

  //Process Workers, Craftsmen, Transport Operators & Labourers
  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(12);
  pdf.text(
    "Process Workers, Craftsmen, Transport Operators & Labourers",
    14,
    209
  );
  pdf.autoTable({
    theme: "grid",
    columns: tableColums.map((col) => ({ ...col, dataKey: col.field })),
    headStyles: { fillColor: "#2cb1bc" },
    body: [
      ...tabledataProduction,
      {
        AgeGroup: ageGroup,
        "Occupation Level":
          "Process Workers, Craftsmen, Transport Operators & Labourers",
        Year: year,
        DeathCount: getDeathCount(7),
      },
    ],

    startY: 212,
    didDrawCell: (data) => {
      const lastRowIndex = tabledataProduction.length;

      if (data.row.index === lastRowIndex) {
        // Highlight the last row's cells with a border
        pdf.setDrawColor(255, 0, 0);
        pdf.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
      }
    },
  });

  pdf.addPage();

  const headerBarColorr = [45, 176, 188]; // RGB color for the background bar
  pdf.setFontSize(10); // Header and footer font size

  // Header Background Bar to the Second Page
  pdf.setFillColor(...headerBarColorr);
  pdf.rect(3, 3, pdf.internal.pageSize.width - 2 * 3, 20, "F");

  const topSentencee = ``;
  const linese = topSentencee.split("\n");
  const lineHeighte = pdf.internal.getFontSize() * 13;

  const textSizee =
    (pdf.getStringUnitWidth(topSentencee) * pdf.internal.getFontSize()) /
    pdf.internal.scaleFactor;

  pdf.setFontSize(12);

  const centerXe = pdf.internal.pageSize.width / 15;
  let centerYe = 0;

  linese.forEach((line) => {
    const textSize =
      (pdf.getStringUnitWidth(line) * pdf.internal.getFontSize()) /
      pdf.internal.scaleFactor;
    const y = centerY;

    pdf.text(line, centerXe, y);
    centerYe += lineHeighte;
  });

  // Header Text to the Second Page
  pdf.setTextColor(255, 255, 255);
  pdf.text(headerText, 18, 12);

  // Footer Text to the Second Page
  pdf.setFontSize(12);
  pdf.setTextColor(0, 0, 0);
  pdf.text(footerText, 10, pdf.internal.pageSize.height - 10);

  //SalesWorkers
  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(12);
  pdf.text("Sales Workers", 14, 42);
  pdf.autoTable({
    theme: "grid",
    columns: tableColums.map((col) => ({ ...col, dataKey: col.field })),
    headStyles: { fillColor: "#2cb1bc" },
    body: [
      ...tabledataSalesWorkers,
      {
        AgeGroup: ageGroup,
        "Occupation Level": "Sales Workers",
        Year: year,
        DeathCount: getDeathCount(4),
      },
    ],

    startY: 45,
    didDrawCell: (data) => {
      const lastRowIndex = tabledataSalesWorkers.length;

      if (data.row.index === lastRowIndex) {
        // Highlight the last row's cells with a border
        pdf.setDrawColor(255, 0, 0);
        pdf.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
      }
    },
  });

  pdf.setLineWidth(1);
  pdf.setDrawColor("#8b8c8b");
  pdf.rect(borderWidth, borderWidth, contentWidth, contentHeight, "D");

  //Service Workers
  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(12);
  pdf.text("Service Workers", 14, 115);
  pdf.autoTable({
    theme: "grid",
    columns: tableColums.map((col) => ({ ...col, dataKey: col.field })),
    headStyles: { fillColor: "#2cb1bc" },
    body: [
      ...tabledataServiceWorkers,
      {
        AgeGroup: ageGroup,
        "Occupation Level": "Service Workers",
        Year: year,
        DeathCount: getDeathCount(5),
      },
    ],

    startY: 118,
    didDrawCell: (data) => {
      const lastRowIndex = tabledataServiceWorkers.length;

      if (data.row.index === lastRowIndex) {
        // Highlight the last row's cells with a border
        pdf.setDrawColor(255, 0, 0);
        pdf.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
      }
    },
  });

  //UnemployedPersons
  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(12);
  pdf.text("Unemployed Persons", 14, 188);
  pdf.autoTable({
    theme: "grid",
    columns: tableColums.map((col) => ({ ...col, dataKey: col.field })),
    headStyles: { fillColor: "#2cb1bc" },
    body: [
      ...tabledataUnemployedPersons,
      {
        AgeGroup: ageGroup,
        "Occupation Level": "Unemployed Persons",
        Year: year,
        DeathCount: getDeathCount(14),
      },
    ],

    startY: 191,
    didDrawCell: (data) => {
      const lastRowIndex = tabledataUnemployedPersons.length;

      if (data.row.index === lastRowIndex) {
        // Highlight the last row's cells with a border
        pdf.setDrawColor(255, 0, 0);
        pdf.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
      }
    },
  });

  pdf.save("all_occupation_levels.pdf");
};
